import React from 'react';

import cn from 'classnames';

import s from './IconButton.module.scss';

export interface IconButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  className?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: 'primary' | 'secondary';
  variant?: 'outline' | 'transparent';
}

/**
 *  IconButton
 *  @param className
 * @param children
 * @param size
 * @param variant
 * @param color
 */

export default function IconButton({
  className = '',
  children,
  size = 'md',
  variant = 'transparent',
  color = 'primary',
  ...props
}: IconButtonProps) {
  return (
    <button
      {...props}
      className={cn(s.IconButton, s[size], s[color], s[variant], className)}
    >
      {children}
    </button>
  );
}
