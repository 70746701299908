import React from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from '../../../../ui/Button';
import { Window, Wrapper, Content, Header, Footer } from '../../../../ui/Modal';
import { useFDAManager } from '../../FDAManagerProvider';

import s from './ConfirmModal.module.scss';

/**
 *  ConfirmMessage
 */

export default function ConfirmModal() {
  const { confirmAgreeModal, agreementModal, setFDA } = useFDAManager();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'fda_content.confirm_modal',
  });

  return (
    <Wrapper
      open={confirmAgreeModal.open}
      className={cn(s.ConfirmMessage)}
      shouldCloseOnOverlayClick
    >
      <Window className={s.window}>
        <Header>
          <h2>{t('title')}</h2>
        </Header>
        <Content className={cn(s.content)}>
          <p>{t('description')}</p>
        </Content>
        <Footer>
          <Button
            color='primary'
            className={s.agree}
            onClick={() => {
              agreementModal.setOpen(true);
              confirmAgreeModal.setOpen(false);
            }}
            size='lg'
          >
            {t('buttons.agree')}
          </Button>
          <Button
            className={s.disagree}
            color='transparent'
            onClick={() => {
              setFDA(false);
              confirmAgreeModal.setOpen(false);
            }}
            size='lg'
          >
            {t('buttons.disagree')}
          </Button>
        </Footer>
      </Window>
    </Wrapper>
  );
}
