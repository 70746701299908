import { useCallback, useEffect, useRef, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

export const DEF_MIN_NETWORK_SPEED = 512;

export const useIsSlowUplSpdDetect = ({
  enabled = false,
  delayIn = 3000,
  delayOut = 1000,
  minNetwork = DEF_MIN_NETWORK_SPEED,
}) => {
  const [isSlow, setIsSlow] = useState<boolean>(false);

  const ti = useRef<NodeJS.Timeout>();

  const slowSpdDeb = useDebouncedCallback(
    () => {
      setIsSlow(true);
    },
    delayIn,
    {
      maxWait: delayIn,
    }
  );

  const normalSpdDeb = useDebouncedCallback(
    () => {
      setIsSlow(false);
    },
    delayOut,
    {
      maxWait: delayOut,
    }
  );

  const reset = useCallback(() => {
    clearInterval(ti.current);
    normalSpdDeb.flush();
    slowSpdDeb.flush();
  }, [normalSpdDeb, slowSpdDeb, ti.current]);

  useEffect(() => {
    if (enabled) {
      console.groupCollapsed('Slow Upload Speed Detect Util');
      reset();
      ti.current = setInterval(() => {
        const curSpeed = Number(sessionStorage.getItem('upload-speed'));
        if (curSpeed > minNetwork) {
          console.log('Checking...');
          normalSpdDeb();
          slowSpdDeb.cancel();
        } else {
          console.log('Slow speed detected:', curSpeed);
          slowSpdDeb();
          normalSpdDeb.cancel();
        }
      }, 1000);
      return () => {
        console.groupEnd();
        reset();
      };
    } else {
      reset();
    }
  }, [enabled, minNetwork]);

  return isSlow;
};
