import React from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useFDAManager } from '../../../../managers/FDAManager';
import CloseButton from '../../../../ui/CloseButton';

import s from './FDAWarnMessage.module.scss';

/**
 *  FDAWarnMessage
 *  @param className
 */

export default function FDAWarnMessage() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'fda_content',
  });
  const { agreementModal, accepted, isFDAUser } = useFDAManager();
  if (accepted || !isFDAUser) return null;
  return (
    <div id='fda-warn-message' className={cn(s.FDAWarnMessage)}>
      <span>{t('header_message')}</span>
      <CloseButton
        className={s.close}
        onClick={() => agreementModal.setOpen(true)}
      />
    </div>
  );
}
