import React from 'react';

import cn from 'classnames';

import { ReactComponent as LoadingIcon } from 'assets/icons/spiner.svg';

import s from './Button.module.scss';

export interface TButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  className?: string;
  color?: 'primary' | 'secondary' | 'white' | 'transparent';
  size?: 'sm' | 'md' | 'lg';
  isLoading?: boolean;
}

/**
 *  Button
 *  @param className
 *  Created by westprophet on 15.07.2024
 * @param children
 * @param size
 * @param color
 * @param props
 */

export default function Button({
  className = '',
  children,
  size = 'md',
  color = 'primary',
  isLoading,
  ...props
}: TButtonProps) {
  return (
    <button
      className={cn(
        s.Button,
        { [s.loading]: isLoading },
        s[color],
        s[size],
        className
      )}
      {...props}
    >
      {children}
      {isLoading && <LoadingIcon className={cn(s.load)} />}
    </button>
  );
}
