import { memo, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';
import FormField from 'components/FormComponents';
import customStyles1 from 'components/FormComponents/SelectInput/customSelectStyles';
import SpinnerSVG from 'components/SpinnerSVG';
import Text from 'components/Text';
import { useSettings } from 'hooks/useSettings';
import { filterPatientRequest, getPatientRequest } from 'redux/actions/patient';
import { getAllTeamMembersRequest } from 'redux/actions/teamMember';
import { teamMemberSelector } from 'redux/selectors/teamMember';
import {
  profileRoleSelector,
  userProfileSelector,
} from 'redux/selectors/userProfile';
import { SettingsEnum } from 'types/settings';
import { B2B_ROLES } from 'utils/constants';
import { DateFormatter } from 'utils/DateFormatter.class';
import { validateSearchExamination } from 'validation/index';

import styles from './FilterForm.module.scss';

const FilterForm = memo(({ isOpen }: { isOpen: boolean }) => {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    examination_date_from: undefined,
    examination_date_to: undefined,
    date: 'all',
    medic_ids: '',
  });

  const { data: teamMemberData, requesting: teamMemberRequesting } =
    useSelector(teamMemberSelector);

  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: 'dashboard.header.filter_form',
  });
  const {
    data: { id: userId },
  } = useSelector(userProfileSelector);

  const userRole = useSelector(profileRoleSelector);
  const allowedToSeeOtherDoctorExams = useSettings<boolean>(
    SettingsEnum.SHOW_ALL_TEAM_PATIENTS
  );

  const isDoctorsFilterEnabled = useMemo(
    () =>
      userRole.status === B2B_ROLES.ACCOUNT_HOLDER ||
      (userRole.status === B2B_ROLES.INVITED && allowedToSeeOtherDoctorExams),
    [userRole.status, allowedToSeeOtherDoctorExams]
  );

  useEffect(() => {
    if (!teamMemberData.members.length) dispatch(getAllTeamMembersRequest());
  }, []);

  useEffect(() => {
    const savedInitValues = localStorage.getItem('filter');

    if (savedInitValues) {
      setInitialValues(JSON.parse(savedInitValues));
    }
  }, []);

  const submitHandler = (values: any) => {
    const data = {
      medic_ids: [isDoctorsFilterEnabled ? values.medic_ids : userId],
      examinations_dates_range: [],
    } as { medic_ids: number[]; examinations_dates_range: number[] };

    /**
     * Added filter options for saving and
     * update after refreshing page
     */
    localStorage.setItem('filter', JSON.stringify(values));

    switch (values.date) {
      case 'all':
        data.examinations_dates_range = [];
        break;
      case 'today':
        data.examinations_dates_range = [
          DateFormatter.getTodayUTCtimestamp() / 1000,
        ];
        break;
      case 'range':
        data.examinations_dates_range = [
          values.examination_date_from,
          values.examination_date_to,
        ];
        break;

      default:
        break;
    }

    dispatch(filterPatientRequest(data));
  };

  const resetHandler = () => {
    dispatch(getPatientRequest());

    localStorage.removeItem('filter');
  };

  const transformDataForSelect = (data: any) => {
    /** Filter only doctor who has active status in the admin panel */
    return data
      ?.filter((item: any) => item.status === 'ACTIVE')
      .map((item: any) => {
        return {
          label: item.full_name,
          value: item.id,
        };
      });
  };

  return (
    <div className={cn(styles.wrapper, { [styles.active]: isOpen })}>
      <SpinnerSVG
        className={styles.spinner}
        active={teamMemberRequesting}
        fillColor={'var(--theme-color)'}
      />
      <div className={styles.body}>
        <Formik
          initialValues={initialValues}
          validationSchema={() =>
            validateSearchExamination(isDoctorsFilterEnabled, i18n.t)
          }
          onSubmit={(values) => submitHandler(values)}
          enableReinitialize={true}
        >
          {({ setFieldValue, values, isValid }) => {
            return (
              <Form className={styles.form}>
                <div className={styles.row}>
                  <Text className={styles['row-title']}>
                    {t('show_examinations_by_date')}
                  </Text>
                  <FormField
                    type={'radio'}
                    component={'radio'}
                    name={'date'}
                    id={'all'}
                    label={t('all')}
                    value={'all'}
                    className={styles.radio}
                  />
                  <FormField
                    type={'radio'}
                    component={'radio'}
                    name={'date'}
                    id={'today'}
                    label={t('today')}
                    value={'today'}
                    className={styles.radio}
                  />
                  <FormField
                    type={'radio'}
                    component={'radio'}
                    name={'date'}
                    id={'range'}
                    label={t('range')}
                    value={'range'}
                    className={styles.radio}
                  />
                </div>
                <div className={styles['date-wrapper']}>
                  <FormField
                    component={'date-picker-input'}
                    name={'examination_date_from'}
                    className={cn(styles.picker, {
                      [styles.disabled]: values['date'] !== 'range',
                    })}
                    disabled={[
                      {
                        from: values['examination_date_to']
                          ? new Date(values['examination_date_to'] * 1000)
                          : undefined,
                        to: new Date(3000, 0, 1),
                      },
                      { after: DateFormatter.getCurrentUTCtimestamp() },
                    ]}
                    placeholder={t('from')}
                    disabledChange={values['date'] !== 'range'}
                    onChange={() => {}}
                    popupLeft
                  />

                  <span className={styles.divider}>—</span>
                  <FormField
                    component={'date-picker-input'}
                    name={'examination_date_to'}
                    className={cn(styles.picker, {
                      [styles.disabled]: values['date'] !== 'range',
                    })}
                    disabled={[
                      {
                        from: new Date(0, 0, 1),
                        to: values['examination_date_from']
                          ? new Date(values['examination_date_from'] * 1000)
                          : undefined,
                      },
                      { after: DateFormatter.getCurrentUTCtimestamp() },
                    ]}
                    placeholder={t('to')}
                    disabledChange={values['date'] !== 'range'}
                    onChange={() => {}}
                  />
                </div>

                {isDoctorsFilterEnabled && (
                  <div className={styles.row}>
                    <Text className={styles['row-title']}>
                      {t('created_by')}
                    </Text>

                    <FormField
                      options={transformDataForSelect(teamMemberData.members)}
                      customStyles={customStyles1}
                      component={'select'}
                      name={'medic_ids'}
                      id={'medic_ids'}
                      isSearchable
                      placeholder={t('doctor_select')}
                      className={styles.select}
                      onChange={(item: any) =>
                        setFieldValue('medic_ids', item.value)
                      }
                    />
                  </div>
                )}

                <div className={styles['button-wrapper']}>
                  <Button
                    type={'submit'}
                    className={cn(styles.button)}
                    disabled={!isValid}
                  >
                    {t('apply')}
                  </Button>
                  <Button
                    appearance={'simple'}
                    className={cn(styles.button)}
                    onClick={resetHandler}
                  >
                    {t('clear')}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
});

FilterForm.displayName = 'FilterForm';

export default FilterForm;
