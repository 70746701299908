import { t } from 'i18next';
import { fork, put, select, takeEvery } from 'redux-saga/effects';

import { updateReportSettings } from 'api/userProfile';
import {
  updateReportSettingsError,
  updateReportSettingsSuccess,
} from 'redux/actions/userProfile';
import { userProfileConstants } from 'redux/constants/userProfile';
import { userProfileSelector } from 'redux/selectors/userProfile';
import { IUpdateReportSettingData } from 'types/profile';
import notify from 'utils/toast';

function* updateReportSettingsWatcher() {
  yield takeEvery(
    userProfileConstants.UPDATE_REPORT_SETTINGS_REQUEST,
    updateReportSettingsWorker
  );
}

function* updateReportSettingsWorker({
  payload: { logo, company_email, company_name },
}: IUpdateReportSettingData) {
  try {
    const logoFileUploaded = logo;
    const formData = new FormData();

    /**
     * Checking if we add a new avatar or
     * just update the profile without an avatar
     */
    if (typeof logoFileUploaded === 'object') {
      formData.append('logo', logoFileUploaded);
    }

    formData.append('company_name', company_name);
    formData.append('company_email', company_email);

    const { data: formInfoUpdate } = yield updateReportSettings(formData);

    const { data: userInfo } = yield select(userProfileSelector);

    if (formInfoUpdate.status === 'Error') {
      throw new Error(formInfoUpdate.message);
    }

    /**
     * Update report settings in the state without
     * send request to the backend
     */
    yield put(
      updateReportSettingsSuccess({
        ...userInfo,
        report_settings: {
          logo:
            typeof logoFileUploaded === 'object'
              ? URL.createObjectURL(logoFileUploaded)
              : logo,
          company_email,
          company_name,
        },
      })
    );
    yield notify('success', t('notifications.company_profile_updated'));
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(updateReportSettingsError(error));
    yield notify('error', errorMessage);
  }
}

export default function* userSaga() {
  yield fork(updateReportSettingsWatcher);
}
