import * as dayjs from 'dayjs';

import useProfileQry from '../queries/user/getProfileQry';

export default function useIsUserTrialExpired(): boolean {
  const profileQry = useProfileQry();
  const profile = profileQry.data?.data;
  // If there is no trial period or the trial period was prepaid
  if (!profile || !profile.plan.trial_ends_at || !profile.is_trial_plan_used)
    return false;

  return dayjs.unix(profile.plan.trial_ends_at).isBefore();
}
