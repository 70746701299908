import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { t } from 'i18next';
import qs from 'query-string';

import { LOCAL_STORAGE_LANGUAGE_KEY } from './constants';
import { getToken } from './helpers';

const baseURL =
  process.env.REACT_APP_ENV !== 'production' &&
  !!localStorage.getItem('api_url')
    ? localStorage.getItem('api_url')
    : process.env.REACT_APP_API_URL;

import { onMaintenanceMode } from 'redux/actions/appSettings';
import { getAuthenticatedFalse } from 'redux/actions/userAuthenticated';
import { store } from 'redux/store';
import { calcUploadedSpeed } from 'utils/slowConnection';
import notify from 'utils/toast';

export interface CacheSlotData {
  responseData: AxiosResponse<any, any>;
  cacheExpirationDate: number;
  method: 'get' | 'post' | 'put' | 'delete';
  requestData: object;
}

/**
 *
 * @param baseURL - base url of request, located in webpack.config
 * @param to - endpoint
 * @param token - user token
 * @param method - one of request methods post | delete | get | patch
 * @param data - sending body data
 * @param params - sending params for GET
 * @return {Promise<any>}
 */

type IFetchData = {
  method: 'get' | 'post' | 'put' | 'delete';
  to: string;
  data?: object;
  params?: object;
  responseType?: string;
  progressBar?: boolean;
  abortSignal?: AbortSignal;
  isCached?: boolean;
  cacheLifetime?: number;
  revalidatePaths?: string[];
  cacheName?: string;
};

/**
 * Change to set default cache lifetime
 * ```ts
 * const DEFAULT_CACHE_LIFE_TIME = 172800000; // 2 days
 * ```
 * Set value to ***Infinity*** to grand infinite cache lifetime
 * ```ts
 * const DEFAULT_CACHE_LIFE_TIME = Infinity;
 * ```
 */
const DEFAULT_CACHE_LIFE_TIME = 604800000; // 7 days

const fetchData = async ({
  method,
  to,
  data = {},
  params = {},
  abortSignal,
  /**
   * @description - if you want to show progress bar, you need to pass true
   * from api function in /api folder. Better to use this param when you upload file
   * @param { boolean } progressBar - if true, then show progress bar
   */
  progressBar = false,
  isCached = false,
  cacheLifetime = DEFAULT_CACHE_LIFE_TIME,
  revalidatePaths,
  cacheName = 'v5',
}: IFetchData) => {
  const token = getToken();

  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
        Language: localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY) ?? 'en',
      }
    : { Language: localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY) ?? 'en' };

  const paramsString = !Object.keys(params).length
    ? ''
    : `?${qs.stringify(params)}`;

  if (isCached) {
    const cache = await caches.open(cacheName);
    const response = await cache.match(to + paramsString);

    if (response) {
      const cachedResponseData: CacheSlotData = await response.json();

      if (
        cachedResponseData.cacheExpirationDate > Number(new Date()) &&
        cachedResponseData.method === method &&
        JSON.stringify(data) === JSON.stringify(cachedResponseData.requestData)
      ) {
        cache.put(
          to + paramsString,
          new Response(
            JSON.stringify({
              ...cachedResponseData,
              cacheExpirationDate: Number(new Date()) + cacheLifetime,
            })
          )
        );

        return Promise.resolve(cachedResponseData.responseData);
      }
    }
  }

  return new Promise((resolve, reject) => {
    axios({
      url: `${baseURL}${to}`,
      method: method,
      headers: headers,
      data: data,
      params: params,
      signal: abortSignal,
      /**
       * @description show progress bar when upload file and set progress in session storage
       * every time when progress change
       */
      ...(progressBar && {
        onUploadProgress: (e) => {
          if (e.total) {
            sessionStorage.setItem(
              'uploadProgressPercent',
              Math.round((e.loaded * 100) / e.total).toString()
            );
            const uploadedSpeed = calcUploadedSpeed(Math.round(e.loaded));
            sessionStorage.setItem('upload-speed', uploadedSpeed.toString());
          }
        },
      }),
    })
      .then((res) => {
        if (isCached || !!revalidatePaths) {
          caches.open(cacheName).then(async (cache) => {
            if (revalidatePaths) {
              for (const path of revalidatePaths) {
                cache.delete(path);
              }
            }

            if (isCached) {
              cache.put(
                to + paramsString,
                new Response(
                  JSON.stringify({
                    responseData: res,
                    cacheExpirationDate: Number(new Date()) + cacheLifetime,
                    method,
                    requestData: data,
                  } as CacheSlotData)
                )
              );
            }
            resolve(res);
          });
        } else {
          resolve(res);
        }
      })
      .catch((error) => {
        if (error.message === 'canceled') {
          reject(error.message);
        }

        if (error.message === 'Network Error') {
          reject(error.message);
        }

        if (error.response) {
          const errors: Record<string, string> = {};

          if (error.response.status === 422) {
            const objectErrors = error.response.data.errors;

            Object.keys(objectErrors).forEach((item) => {
              errors[item] = objectErrors[item];
            });
          }

          // Unauthenticated
          if (error.response.status === 401) {
            const token = getToken();

            if (token) {
              const apiUrl = localStorage.getItem('api_url');

              localStorage.clear();

              if (process.env.REACT_APP_ENV !== 'production' && apiUrl) {
                localStorage.setItem('api_url', apiUrl);
              }

              store.dispatch(getAuthenticatedFalse());
              notify('info', t('notifications.session_expired'));
            }

            return;
          }

          if (error.response.status === 503) {
            if (token) {
              store.dispatch(onMaintenanceMode());

              return;
            } else {
              notify('info', t('notifications.site_under_maintenance'));

              return;
            }
          }

          if (error.response.status === 413) {
            return reject(
              new Error(
                error.response.data.message ||
                  error.response.data.errors ||
                  t('notifications.file_folder_is_too_large')
              )
            );
          }

          if (
            error.response.status === 423 ||
            error.response.status === 422 ||
            error.response.status === 404 ||
            error.response.status === 403 ||
            error.response.status === 400
          ) {
            if (error.response.data.message) {
              reject(new Error(error.response.data.message));
            } else if (error.response.data.errors) {
              return reject(error.response.data.errors);
            } else {
              return reject(error.message);
            }
          }
          return reject(error);
        }
      });
  });
};

export default fetchData;
