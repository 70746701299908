import FlashMessage from './FlashMessage';
import useFlashMessage from './hooks/useFlashMessage';
import Description from './sections/Description';
import Title from './sections/Title';

export { FlashMessage, Title, Description, useFlashMessage };

export default {
  Wrapper: FlashMessage,
  Title,
  Description,
};
