import React from 'react';

import cn from 'classnames';

import { TFlashMessageType } from '../../FlashMessage';
import useDefaultIcon from '../../hooks/useDefaultIcon';

import s from './Title.module.scss';

interface Props {
  className?: string;
  title: React.ReactNode;
  icon?: TFlashMessageType | boolean;
}

/**
 *  Title for FlashMessage
 *  @param className
 * @param title
 * @param icon
 */
export default function Title({ className = '', title, icon = true }: Props) {
  const Icon = useDefaultIcon(icon);

  return (
    <div className={cn(s.Title, className)}>
      {Icon && <Icon />}
      <h3>{title}</h3>
    </div>
  );
}
