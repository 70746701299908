import {
  DefinedInitialDataOptions,
  UndefinedInitialDataOptions,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import { api } from '../../_api';
import { TSuccess } from '../../_api/AltrisRESTClient/routes/user/getProfile';

export type TProfileQry = UseQueryResult<TSuccess>;
export type TProfileOpts = Omit<
  DefinedInitialDataOptions<TSuccess>,
  'queryKey' | 'initialData'
>;

export const getProfileKey = () => ['user', 'getProfile'];

export default function useProfileQry(
  options?: Omit<
    DefinedInitialDataOptions<TSuccess>,
    'queryKey' | 'initialData'
  >
) {
  const opts = getProfileOpts(options);
  return useQuery<TSuccess>(opts);
}

export function getProfileOpts(
  options?: TProfileOpts
): UndefinedInitialDataOptions<TSuccess> {
  return {
    ...options,
    queryKey: getProfileKey(),
    queryFn: ({ signal }) => api.user.getProfile({ signal }),
    enabled: options?.enabled ?? true,
  };
}
