import { AxiosRequestConfig } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError, TResponseSuccess } from '../../types';

import { TProfile } from 'types/profile';

export interface TAPIGetProfile {
  TError: TResponseError;
  TSuccess: TResponseSuccess<TProfile>;
}

export type TError = TAPIGetProfile['TError'];
export type TSuccess = TAPIGetProfile['TSuccess'];

export async function getProfile(
  this: BaseRESTClient,
  config?: AxiosRequestConfig
) {
  try {
    const { data } = await this.client.get<TSuccess>('/user', config);
    return data;
  } catch (error: any | TError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
