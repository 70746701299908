import { ElementRef, memo, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { useFDAManager } from '../../../managers/FDAManager';

import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { MOCK_EXAM_ID } from 'components/Modal/OnboardingTour/mockData/mockData';
import SeverityLevel from 'components/SeverityLevel';
import SpinnerSVG from 'components/SpinnerSVG';
import Text from 'components/Text';
import { useIsDemo } from 'hooks/useIsDemo';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { useSettings } from 'hooks/useSettings';
import { getExaminationStatusRequest } from 'redux/actions/examination';
import { IPatientExam } from 'types/patients';
import { SeverityLevelType } from 'types/profile';
import { SettingsEnum } from 'types/settings';
import { EXAMINATION_STATUS } from 'utils/constants';
import { DateFormatter } from 'utils/DateFormatter.class';
import {
  deleteExaminationModal,
  updateExaminationModal,
} from 'utils/openModals';

import styles from './ExaminationItem.module.scss';

interface ExaminationItemProps {
  examination: IPatientExam;
  isActive: boolean;
  openExaminationDetailHandler: (id: string) => void;
}

const ExaminationItem = memo(
  ({
    examination,
    isActive,
    openExaminationDetailHandler,
  }: ExaminationItemProps) => {
    const {
      id,
      eye,
      total_scans,
      pid,
      examination_date,
      width,
      examination_status,
      severity_level = '',
      source_type,
    } = examination;

    const contextMenuExamination = useRef<ElementRef<'div'>>(null);
    const dispatch = useDispatch();

    const timeFormat = useSettings<string>(SettingsEnum.TIME_FORMAT);

    useOnClickOutside(contextMenuExamination, () => setOpen(false));

    const { t } = useTranslation(undefined, {
      keyPrefix: 'components.examination_list.item',
    });

    const [open, setOpen] = useState(isActive);

    const isDemo = useIsDemo();

    useEffect(() => {
      setOpen(isActive);
    }, [isActive]);

    useEffect(() => {
      /**
       * FIXME: Find better solution
       */
      if (
        examination_status === EXAMINATION_STATUS.PROCESSING &&
        id !== MOCK_EXAM_ID
      ) {
        dispatch(
          // @ts-ignore
          getExaminationStatusRequest(examination)
        );
      }
    }, []);

    const fdaManager = useFDAManager();
    switch (examination_status) {
      case EXAMINATION_STATUS.PROCESSING:
        return (
          <div className={cn(styles.item, styles.disabled)}>
            <SpinnerSVG
              active={true}
              className={styles.spinner}
              fillColor={'var(--theme-color)'}
            />
            <Text className={styles.title}>{t('eye')}: ***** (**), N/A/*</Text>
            <div className={styles.date}>
              <span>**/**/****</span>
            </div>
          </div>
        );

      case EXAMINATION_STATUS.FAILED:
        return (
          <div className={cn(styles.item, styles.failed)}>
            <Text className={styles.title}>{t('examination_failed')}</Text>
            <div
              onClick={(e) => {
                e.stopPropagation();
                deleteExaminationModal(id, pid);
              }}
            >
              <TrashIcon />
            </div>
          </div>
        );

      default:
        return (
          <div
            className={cn(styles.item, {
              'shepherd-examination': id === MOCK_EXAM_ID,
            })}
            ref={contextMenuExamination}
            onClick={(e) => {
              e.stopPropagation();
              if (fdaManager.isFDAUser && fdaManager.accepted === null)
                fdaManager.agreementModal.setOpen(true);
              else openExaminationDetailHandler(id);
            }}
          >
            <div className={styles['title-wrapper']}>
              <div className={styles.icon}>
                <SeverityLevel
                  level={severity_level as SeverityLevelType}
                  type='examination'
                />
              </div>
              <div className={styles.title}>
                {t('eye')}:{' '}
                {eye
                  ? eye === 'OD'
                    ? `${t('right_short')} (OD)`
                    : `${t('left_short')} (OS)`
                  : 'N/A'}
                {width ?? 'N/A'}/{total_scans ?? 'N/A'}
              </div>
            </div>

            <div className={styles.format}>
              {source_type === 'dicom' ? '| DICOM |' : `| ${t('image')} |`}
            </div>

            <div className={styles.date}>
              <span>{DateFormatter.getDate(timeFormat, examination_date)}</span>
            </div>

            {!isDemo && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen((state) => !state);
                }}
                className={styles.dots}
              >
                <DotsIcon />
              </div>
            )}

            <CSSTransition
              in={open}
              timeout={300}
              classNames={'alert'}
              unmountOnExit
            >
              <div className={styles.submenu}>
                <ul className={styles.list}>
                  <li
                    onClick={(e) => {
                      e.stopPropagation();
                      updateExaminationModal(examination);
                    }}
                  >
                    <EditIcon /> <span>{t('edit')}</span>
                  </li>
                  <li
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteExaminationModal(id, pid);
                    }}
                  >
                    <TrashIcon /> <span>{t('delete')}</span>
                  </li>
                </ul>
              </div>
            </CSSTransition>
          </div>
        );
    }
  }
);

ExaminationItem.displayName = 'ExaminationItem';

export default ExaminationItem;
