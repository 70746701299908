import React from 'react';

import cn from 'classnames';

import { TFlashMessageType } from '../../FlashMessage';
import useDefaultIcon from '../../hooks/useDefaultIcon';

import s from './Description.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  className?: string;
  children: React.ReactNode;
  icon?: TFlashMessageType | boolean;
}

/**
 *  Description
 *  Created by westprophet on 23.07.2024
 *  @param className
 * @param children
 * @param icon
 */

export default function Description({
  className = '',
  children,
  icon = false,
}: Props) {
  const Icon = useDefaultIcon(icon); // get def Icon by type
  return (
    <div className={cn(s.Description, className)}>
      {Icon && <Icon />}
      <p>{children}</p>
    </div>
  );
}
