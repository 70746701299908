import { memo, useContext, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ShepherdTourContext } from 'react-shepherd';

import useIsSlowConnection from './hooks/useIsSlowConnection';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as UploadFilesIcon } from 'assets/icons/upload-files.svg';
import fakeDICOMFile from 'assets/images/dicom.png';
import Button from 'components/Button';
import Text from 'components/Text';
import useLocalStorage from 'hooks/useLocalStorage';
import { CREATE_EXAMINATION_FLOW } from 'utils/constants';
import notify from 'utils/toast';

import styles from './FileUpload.module.scss';

const FileUpload = memo(
  ({
    accept,
    multiple = false,
    name,
    customClass,
    dicom,
    id = 'upload-data',
    label,
  }) => {
    const { setFieldValue, setValues, values, isSubmitting } =
      useFormikContext();
    const tour = useContext(ShepherdTourContext);
    const { localStorageKey: onboardingStep } = useLocalStorage('onboarding');

    const inputImageRef = useRef(null);
    const inputWrapperRef = useRef(null);
    const [scansCount, setScansCount] = useState(0);
    /**
     * Local state for multiple upload dicom files
     */
    const [fileList, setFileList] = useState([]);

    const isSlowConnection = useIsSlowConnection();

    useEffect(() => {
      sessionStorage.setItem('isSlowConnection', isSlowConnection);

      return () => sessionStorage.removeItem('isSlowConnection');
    }, [isSlowConnection]);

    const { t, i18n } = useTranslation(undefined, {
      keyPrefix: 'components.form_components.file_upload',
    });

    useEffect(() => {
      if (isSubmitting) {
        setScansCount(0);
      }
    }, [isSubmitting]);

    const onDragEnterHandler = () =>
      inputWrapperRef.current?.classList.add(styles.dragenter);
    const onDragLeaveHandler = () =>
      inputWrapperRef.current?.classList.remove(styles.dragenter);
    const onDragDropHandler = () =>
      inputWrapperRef.current?.classList.remove(styles.dragenter);
    /**
     * Create fake file for simulate upload dicom while onboarding
     */
    const dragFakeImageHandler = (onboardingStep) => () => {
      if (onboardingStep) {
        const file = new File(['(⌐□_□)'], 'newDicom.dicom', {
          type: 'application/dicom',
        });
        setValues({
          ...values,
          ['flow']: CREATE_EXAMINATION_FLOW.DICOM_IN_PATIENT,
          [name]: file,
        });
        tour.show('create-examination-step-6');
        tour.removeStep('create-examination-step-5');
        setScansCount(1);
        setFileList([file]);
      }
    };
    const onClickHandler = (event, onboardingStep) => {
      if (onboardingStep) {
        event.preventDefault();
      }
    };

    const onChangeDICOMHandler = (event) => {
      if ([...fileList, ...Array.from(event.currentTarget.files)].length > 2) {
        notify('error', i18n.t('notifications.you_can_add_only_2_dicom'));
        return;
      }

      setFileList([...fileList, ...Array.from(event.currentTarget.files)]);

      /**
       * Changing field @flow to change validation schema, Dicom, or images flow
       */
      setValues({
        ...values,
        ['flow']: values['flow'],
        [name]: [...fileList, ...Array.from(event.currentTarget.files)],
      });

      setScansCount(event.currentTarget.files.length);

      notify(
        'success',
        i18n.t('notifications.your_data_was_added_successfully')
      );
    };

    const onChangeImageHandler = (event) => {
      const file = event.currentTarget.files[0];
      const acceptFile = accept.map((item) => item.replace(/[\W\d_]/g, ''));

      if (
        /**
         * We should check file extension by name not for type,
         * windows os has problem with Dicom type detection
         */
        !acceptFile.some((item) => file.name.toLowerCase().includes(item))
      ) {
        notify(
          'error',
          i18n.t('notifications.you_cant_add_file_of_this_format')
        );
        return;
      }

      /**
       * if !multiple we should set file to formik field as one file and sent to server
       * f.e. Contact us Page
       * In other cases we should set file to local state and sent to server as array of files
       */
      if (!multiple) {
        setValues({
          ...values,
          [name]: event.currentTarget.files[0],
        });
      } else {
        /**
         * Changing field @flow to change validation schema, Dicom, or images flow
         */
        setValues({
          ...values,
          ['flow']: values['flow'],
          [name]: event.currentTarget.files,
        });
      }

      setScansCount(event.currentTarget.files.length);

      notify(
        'success',
        i18n.t('notifications.your_data_was_added_successfully')
      );
    };

    const onChangeHandler = (event) => {
      if (!event.currentTarget.files?.length) return;

      if (dicom) {
        return onChangeDICOMHandler(event);
      } else {
        return onChangeImageHandler(event);
      }
    };

    const onClearHandler = (event) => {
      event.stopPropagation();
      setFieldValue(name, null);
      /**
       * for reset input when validation failed
       * possibility to choose the same picture
       */
      if (inputImageRef.current !== null) {
        inputImageRef.current.value = '';
      }
      setScansCount(0);
      setFieldValue(name, null);
      setFileList([]);
    };

    const fileRemove = (file) => {
      const updatedList = [...fileList];

      if (fileList.length === 1) {
        setFileList([]);
        setFieldValue(name, null);
        return;
      }

      updatedList.splice(fileList.indexOf(file), 1);
      setFileList(updatedList);
      setFieldValue(name, updatedList);
    };

    return (
      <>
        <div
          className={cn(styles.upload, customClass)}
          onDragEnter={onDragEnterHandler}
          onDragLeave={onDragLeaveHandler}
          onDrop={onDragDropHandler}
          ref={inputWrapperRef}
        >
          <input
            type='file'
            name={name}
            accept={accept.join(', ')}
            multiple={multiple}
            className={styles.file}
            id={id}
            /**
             * onDrop & onDragLeave with the same handlers need for cross browser
             */
            onDrop={dragFakeImageHandler(onboardingStep)}
            onDragLeave={dragFakeImageHandler(onboardingStep)}
            onChange={onChangeHandler}
            ref={inputImageRef}
            /**
             * onClickHandler need for prevent default behavior on onboarding
             * when we click on input and open file explorer
             */
            onClick={(event) => {
              onClickHandler(event, onboardingStep);
            }}
          />
          {fileList.length === 0 && (
            <>
              <UploadFilesIcon className={styles.icon} />
              <Text className={styles['upload-text']}>
                {t('upload_your_date_here')}
              </Text>
            </>
          )}
          {scansCount ? (
            multiple && dicom ? (
              !!fileList.length && (
                <div className={styles.list}>
                  {fileList.map((item, index) => (
                    <div key={index} className={styles['dicom-item']}>
                      <div className={styles['dicom-info']}>
                        <img
                          src={fakeDICOMFile}
                          className={styles['dicom-image']}
                        />
                        <p className={styles['dicom-name']}>{item.name}</p>
                        <span>{(item.size / 1024 / 1024).toFixed(2)}mb</span>
                      </div>

                      <Button
                        type='button'
                        className={cn(styles.clear, styles.delete)}
                        appearance={'simple'}
                        onClick={(event) => {
                          event.stopPropagation();
                          fileRemove(item);
                        }}
                      >
                        <TrashIcon />
                      </Button>
                    </div>
                  ))}
                  {fileList.length !== 0 && (
                    <Button
                      className={styles['add-more-files']}
                      appearance={'simple'}
                      type='button'
                      disabled={fileList.length === 2}
                      onClick={() => inputImageRef.current.click()}
                    >
                      {t('add_more_examinations')}
                    </Button>
                  )}
                </div>
              )
            ) : (
              <>
                <Text className={styles['upload-file-text']}>
                  {t('you_added_files', { count: scansCount })}
                </Text>
                <Button
                  className={styles.clear}
                  appearance={'simple'}
                  onClick={() => onClearHandler(event)}
                >
                  {t('remove_file', { ending: scansCount === 1 ? '' : 's' })}
                </Button>
              </>
            )
          ) : null}
        </div>
        <label className={cn(styles.label)} htmlFor={id}>
          {label ?? t('upload_file')}
        </label>
      </>
    );
  }
);

FileUpload.displayName = 'FileUpload';

export default FileUpload;
