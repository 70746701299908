import React, { createContext } from 'react';

import cn from 'classnames';

import { TInfoType, TUrgencyColor } from 'types/main';

import s from './FlashMessage.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
  type: TFlashMessageType;
}

export type TFlashMessageType =
  | TInfoType
  | Lowercase<TUrgencyColor>
  | TUrgencyColor;
/**
 *  FlashMessage
 *  @param className
 *  Created by westprophet on 23.07.2024
 * @param children
 * @param type
 */

export const FlashMessageContext = createContext<
  TInfoType | Lowercase<TUrgencyColor> | TUrgencyColor
>('error');

export default function FlashMessage({
  className = '',
  children,
  type,
}: Props) {
  return (
    <FlashMessageContext.Provider value={type}>
      <div className={cn(s.FlashMessage, s[type], className)}>{children}</div>
    </FlashMessageContext.Provider>
  );
}
