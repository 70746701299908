import React, { useContext } from 'react';

import FDAManagerContext from './FDAManagerContext';
import AgreementModal from './modals/AgreementModal';
import ConfirmModal from './modals/ConfirmModal';
import useInitialState from './useInitialState';

interface TFDAManagerProviderProps {
  children: React.ReactNode;
}

export const useFDAManager = () => useContext(FDAManagerContext);

export default function FDAManagerProvider({
  children,
}: TFDAManagerProviderProps) {
  const value = useInitialState();

  return (
    <FDAManagerContext.Provider value={value}>
      {children}
      <AgreementModal />
      <ConfirmModal />
    </FDAManagerContext.Provider>
  );
}
