import { USER } from './endpoints';

import { IChangePassword, IGetCodeForPassword } from 'types/profile';
import fetchData from 'utils/fetchData';

export const getCodeUpdateUserPassword = async (data: IGetCodeForPassword) =>
  fetchData({
    method: 'post',
    to: USER.USER_GET_CODE_FOR_UPDATE_PASSWORD,
    data: data,
  });

export const updateUserPassword = async (data: IChangePassword) =>
  fetchData({ method: 'put', to: USER.USER_UPDATE_PASSWORD, data: data });

export const updateUserInfo = async (data: FormData) =>
  fetchData({
    method: 'post',
    to: USER.USER_UPDATE_INFO,
    data: data,
  });

export const getUserInfo = async () =>
  fetchData({ method: 'get', to: USER.USER_INFO, data: {} });

export const checkUserEmail = async (data: FormData) =>
  fetchData({
    method: 'post',
    to: USER.CHECK_EMAIL,
    data: data,
  });

export const updateReportSettings = async (data: FormData) =>
  fetchData({
    method: 'post',
    to: USER.USER_UPDATE_REPORT_SETTINGS,
    data: data,
  });

export const getProgressionSettings = (abortSignal: AbortSignal) =>
  fetchData({
    method: 'get',
    to: USER.PROGRESSION_SETTINGS,
    abortSignal,
  });

export const updateProgressionSettings = async (data: {
  segmentation_classes: Record<string, boolean>;
}) => {
  return fetchData({
    method: 'post',
    to: USER.PROGRESSION_SETTINGS,
    data,
  });
};
