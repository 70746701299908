import { Fragment, ReactNode } from 'react';

import ReactGA from 'react-ga4';
import { select, type SelectEffect } from 'redux-saga/effects';

import { IMenuLinks } from './linksData';

import { StateSchema } from 'redux/types';
import {
  IMeasurementsPolygonScan,
  IMeasurementsScan,
  OneScanMaskTypes,
} from 'types/examination';
import { TPlan } from 'types/profile';
import { ISubscriptionPlan, ISubscriptionPrice } from 'types/subscription';
import { DEMO_ACCOUNT_GOOGLE_ANALYTICS, USER_ROLES } from 'utils/constants';

export const generatePatientId = (): string => `AUTO${Date.now()}`;

export const getToken = (): string | null => {
  return localStorage.getItem('token');
};

export const ga = (action: string, category = 'All Users'): void => {
  const isDemo = window.location.hostname.startsWith('demo.');
  const isDemoAction = Object.values(DEMO_ACCOUNT_GOOGLE_ANALYTICS).includes(
    action as DEMO_ACCOUNT_GOOGLE_ANALYTICS
  );

  if ((isDemo && isDemoAction) || (!isDemo && !isDemoAction)) {
    return ReactGA.event({
      category: category,
      action: action,
    });
  }
};

export const rolePermissionsCheck = (
  links: IMenuLinks[],
  role?: USER_ROLES
) => {
  return links.filter(
    (item: IMenuLinks) => role && item.allowedRole.includes(role)
  );
};

export const findCurrentPlanPrice = (
  allPlans: ISubscriptionPlan[],
  currentUserPlan: TPlan
) => {
  const nextPlan: ISubscriptionPlan | undefined = allPlans.find(
    (item) => item.slug === currentUserPlan.slug
  );

  const priceForPlan: (ISubscriptionPrice | undefined)[] | undefined =
    nextPlan?.options.map((item) =>
      item.prices.find(
        (plan) => plan.recurring.interval == currentUserPlan.recurring
      )
    );

  return (
    priceForPlan?.find(
      (item: ISubscriptionPrice | undefined) => item?.id === currentUserPlan.id
    ) || {
      id: 0,
      stripe_id: '',
      description: '',
      price: '',
      discount_price: '',
      currency: '',
      currency_symbol: '',
      trial_days: 0,
      type: '',
      recurring: {
        aggregate_usage: null,
        interval: 'month',
        interval_count: 1,
        trial_period_days: null,
        usage_type: 'licensed',
      },
      plan_slug: '',
    }
  );
};

export function getRandomNumber(max: number) {
  return Math.floor(Math.random() * max);
}

export function getRandomString() {
  return Math.random().toString(36).slice(2, 12);
}

export function getComponentCount(component: ReactNode, count?: number) {
  return Array(count ? count : getRandomNumber(10))
    .fill(component)
    .map((item, index) => <Fragment key={index}>{item}</Fragment>);
}

/**
 * @param date A Date object created from a date to which you want to add days.
 * @param days count of days what you want to add to original date
 * @returns Date with additional days in Date object format
 */
export function addDaysToDate(date: Date, days: number) {
  date.setDate(date.getDate() + days);

  return date;
}

export const checkOddPathologies = (className: string) => {
  let currentClass = className;
  let last = false;

  return function (newClass: string) {
    if (last && currentClass === newClass) {
      last = true;
      return true;
    } else if (!last && currentClass === newClass) {
      last = false;
      return false;
    } else if (last === false) {
      last = true;
      currentClass = newClass;
      return true;
    } else {
      last = false;
      currentClass = newClass;
      return false;
    }
  };
};

export function removeInvalidPolygons(
  scanData: IMeasurementsScan
): IMeasurementsScan {
  const cleanData: IMeasurementsPolygonScan[] = [];

  scanData?.pathologies?.map((item: IMeasurementsPolygonScan) => {
    if (
      item.linear.polygon_max_bb.max_x === 0 &&
      item.linear.polygon_max_bb.max_y === 0
    )
      return;

    cleanData.push(item);
  });

  // New object with removed invalid polygons, also you can rewrite object for testing.
  const concatScan: IMeasurementsScan = {
    link: scanData.link,
    thumbnail_link: scanData['thumbnail_link'],
    pathologies: cleanData,
    thickness_maps: scanData['thickness_maps'],
    etdrs_calculation_zone: scanData['etdrs_calculation_zone'],
  };

  return concatScan;
}

export function generateRandomHex(): string {
  const letters = '0123456789ABCDEF';

  let color = '#';

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
}

export const generateMeasLineNumber = (): string => `${Date.now()}`;

export function downloadObjectAsJson(exportObj: object, exportName: string) {
  const dataStr =
    'data:text/json;charset=utf-8,' +
    encodeURIComponent(JSON.stringify(exportObj));
  const downloadAnchorNode = document.createElement('a');

  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', exportName + '.json');
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export function selectState<T>(selector: (s: StateSchema) => T): SelectEffect {
  return select(selector);
}

export function sortSegmentationInstances(scans: OneScanMaskTypes[]) {
  return [...scans].sort(
    (firstScan, secondScan) =>
      firstScan.list_position - secondScan.list_position
  );
}
