import { createMockFullExaminationData } from 'components/Modal/OnboardingTour/mockData/mockData';
import { ExamActionTypes } from 'redux/constants/examination';
import { IExamActionTypes } from 'redux/types/examination';
import { TExamination, ExaminationStateSchema } from 'types/examination';

const initialState: ExaminationStateSchema = {
  requesting: false,
  errors: null,
  showHeatmap: false,
  data: {
    segmentation_layers_status: null,
    segmentations_status: null,
    total_scans: 0,
    width: '5mm',
    able_to_get_statistics: false,
    created_at: 0,
    direction: 'Horizontal',
    examination_date: 0,
    examination_status: null,
    eye: 'OS',
    has_default_etdrs_statistics: false,
    id: '',
    is_dicom: false,
    measurements_status: 'not_required',
    medic_id: 0,
    medic_name: '',
    no_pathology: false,
    note: null,
    patient_birth_date: 0,
    patient_gender: 'Male',
    patient_id: 0,
    patient_name: '0',
    patient_surname: '',
    pid: 'AUTO0000000000000',
    scans: [],
  },
  singleLayers: {
    requesting: false,
    data: null,
  },
  measurements: {
    data: undefined,
    manual: undefined,
    isAreaGroupsExpanded: false,
    isVolumeGroupsExpanded: false,
    volumeMeasurementValue: 'mm',
  },
  compareBoth: [],
  compareComparison: [],
  compareProgress: {
    isRequestingProgressionState: false,
    examsData: [],
    isRequestingCommonPathologiesData: false,
    commonPathologiesData: [],
    isRequestingCommonHeatmapsData: false,
    commonHeatmapsData: {},
    isRequestingPathologyScansData: false,
    pathologyScansData: {},
  },
  scanSegmentation: undefined,
  segmentationReport: undefined,
  glaucoma: {
    isRequesting: false,
    isUnavailable: false,
  },
};

const examinationReducer = (state = initialState, action: IExamActionTypes) => {
  switch (action.type) {
    case ExamActionTypes.RESET_EXAMINATION_STATE: {
      // Remove old saved segmentations for report
      localStorage.removeItem('segmentation_report');
      localStorage.removeItem('analyse-severity');

      return initialState;
    }

    //onboarding tour
    case ExamActionTypes.GET_DATA_FOR_ONBOARDING_TOUR: {
      return createMockFullExaminationData();
    }

    //CREATE EXAMINATION
    case ExamActionTypes.CREATE_EXAMINATION_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };

    case ExamActionTypes.CREATE_EXAMINATION_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };

    case ExamActionTypes.CREATE_EXAMINATION_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    // GET EXAMINATION
    case ExamActionTypes.GET_EXAMINATION_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };

    case ExamActionTypes.GET_EXAMINATION_SUCCESS:
      // When we get current examination data, we add this data to tabs both eyes, comparison, progression
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        compareBoth:
          state.compareBoth.length > 1
            ? [{ ...action.payload }, { ...state.compareBoth[1] }]
            : [{ ...action.payload }],
        compareComparison:
          state.compareComparison.length > 1
            ? [{ ...action.payload }, { ...state.compareComparison[1] }]
            : [{ ...action.payload }],
        compareProgress:
          state.compareProgress.examsData.length > 1
            ? {
                ...state.compareProgress,
                examsData: [
                  { ...action.payload },
                  ...state.compareProgress.examsData.slice(1),
                ],
              }
            : { ...state.compareProgress, examsData: [{ ...action.payload }] },
        requesting: false,
        errors: null,
      };

    case ExamActionTypes.GET_EXAMINATION_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    //DELETE EXAMINATION
    case ExamActionTypes.DELETE_EXAMINATION_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };

    case ExamActionTypes.DELETE_EXAMINATION_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };

    case ExamActionTypes.DELETE_EXAMINATION_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    //EDIT EXAMINATION
    case ExamActionTypes.UPDATE_EXAMINATION_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };

    case ExamActionTypes.UPDATE_EXAMINATION_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };

    case ExamActionTypes.UPDATE_EXAMINATION_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    // COMPARE EXAMINATION
    case ExamActionTypes.COMPARE_EXAMINATION_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };

    case ExamActionTypes.COMPARE_EXAMINATION_SUCCESS:
      return {
        ...state,
        requesting: false,
        errors: null,
      };

    case ExamActionTypes.COMPARE_EXAMINATION_BOTH_SUCCESS:
      return {
        ...state,
        compareBoth: action.payload,
        requesting: false,
        errors: null,
      };

    case ExamActionTypes.COMPARE_EXAMINATION_COMPARISON_SUCCESS:
      return {
        ...state,
        compareComparison: action.payload,
        requesting: false,
        errors: null,
      };

    case ExamActionTypes.COMPARE_EXAMINATION_PROGRESS_SCANS_DATA_SUCCESS:
      return {
        ...state,
        compareProgress: {
          ...state.compareProgress,
          ...action.payload,
        },
        requesting: false,
        errors: null,
      };

    case ExamActionTypes.COMPARE_EXAMINATION_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    // FILTER EXAMINATION
    case ExamActionTypes.FILTER_EXAMINATION_PROGRESS:
      return {
        ...state,
        requesting: false,
        compareProgress: {
          ...state.compareProgress,
          examsData: action.payload,
        },
        errors: null,
      };

    // SEGMENTATION EXAMINATION
    case ExamActionTypes.SCAN_SEGMENTATION_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };

    case ExamActionTypes.SCAN_SEGMENTATION_SUCCESS:
      return {
        ...state,
        scanSegmentation: action.payload,
        requesting: false,
        errors: null,
      };

    case ExamActionTypes.SCAN_SEGMENTATION_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    // SEGMENTATION EXAMINATION
    case ExamActionTypes.SCAN_SEGMENTATION_REPORT_REQUEST:
      return {
        ...state,
        requesting: true,
        errors: null,
      };

    case ExamActionTypes.SCAN_SEGMENTATION_REPORT_SUCCESS:
      return {
        ...state,
        segmentationReport: action.payload,
        requesting: false,
        errors: null,
      };

    case ExamActionTypes.SCAN_SEGMENTATION_REPORT_ERROR:
      return {
        ...state,
        requesting: false,
        errors: action.payload,
      };

    // LAYERS
    case ExamActionTypes.GET_LAYERS_STATUS_REQUEST:
      return {
        ...state,
        singleLayers: { ...state.singleLayers, requesting: true },
        errors: null,
      };

    case ExamActionTypes.GET_LAYERS_STATUS_SUCCESS:
      return {
        ...state,
        singleLayers: { ...state.singleLayers, requesting: false },
        errors: null,
      };

    case ExamActionTypes.GET_LAYERS_STATUS_ERROR:
      return {
        ...state,
      };

    case ExamActionTypes.SCAN_LAYERS_REQUEST:
      return {
        ...state,
        singleLayers: { ...state.singleLayers, requesting: true },
        errors: null,
      };

    case ExamActionTypes.SCAN_LAYERS_SUCCESS:
      return {
        ...state,
        singleLayers: {
          ...state.singleLayers,
          requesting: false,
          data: action.payload,
        },
        errors: null,
      };

    case ExamActionTypes.SCAN_LAYERS_ERROR:
      return {
        ...state,
        singleLayers: { ...state.singleLayers, requesting: false },
        errors: action.payload,
      };

    case ExamActionTypes.SET_FAVORITE_SCAN_SUCCESS: {
      const filteredBoth = state.compareBoth.filter(
        (item: TExamination) => item.id !== action.payload.id
      );

      const filteredComp = state.compareComparison.filter(
        (item: TExamination) => item.id !== action.payload.id
      );
      const filteredProgress = state.compareProgress.examsData.filter(
        (item: TExamination) => item.id !== action.payload.id
      );

      return {
        ...state,
        data: action.payload,
        compareBoth: [{ ...action.payload }, ...filteredBoth],
        compareComparison: [{ ...action.payload }, ...filteredComp],
        compareProgress: {
          ...state.compareProgress,
          examsData: [{ ...action.payload }, ...filteredProgress],
        },
        errors: null,
      };
    }

    case ExamActionTypes.GET_ETDRS_MAIN_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          etdrs: {
            ...state.data.etdrs,
            main: {
              requesting: true,
            },
          },
        },
        errors: null,
      };

    case ExamActionTypes.GET_ETDRS_MAIN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          etdrs: {
            ...(state.data?.etdrs ?? {}),
            requested: true,
            main: {
              data: action.payload,
            },
          },
        },
        compareProgress: {
          ...state.compareProgress,
          examsData: state.compareProgress.examsData.map((data) => {
            if (data.id !== state.data.id) return data;

            return {
              ...data,
              etdrs: {
                main: {
                  data: action.payload,
                },
              },
            };
          }),
        },
        errors: null,
      };

    case ExamActionTypes.GET_ETDRS_SINGLE_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          etdrs: {
            ...state.data?.etdrs,
            single: {},
          },
        },
        errors: null,
      };

    case ExamActionTypes.GET_ETDRS_SINGLE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          etdrs: {
            ...state.data?.etdrs,
            single: {
              layers: action.payload.layers,
              data: action.payload.data,
            },
          },
        },
        errors: null,
      };

    case ExamActionTypes.GET_ETDRS_RANGE_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          etdrs: {
            ...state.data?.etdrs,
            range: {},
          },
        },
        errors: null,
      };

    case ExamActionTypes.GET_ETDRS_RANGE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          etdrs: {
            ...state.data?.etdrs,
            range: {
              layers: action.payload.layers,
              data: action.payload.data,
            },
          },
        },
        errors: null,
      };

    case ExamActionTypes.GET_ETDRS_GCC_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          etdrs: {
            ...state.data?.etdrs,
            gcc: {},
          },
        },
        errors: null,
      };

    case ExamActionTypes.GET_ETDRS_GCC_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          etdrs: {
            ...state.data?.etdrs,
            gcc: {
              layers: action.payload.layers,
              data: action.payload.data,
            },
          },
        },
        errors: null,
      };

    case ExamActionTypes.GET_ETDRS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          etdrs: {
            requested: true,
          },
        },
        errors: action.payload,
      };

    case ExamActionTypes.UPDATE_COMPARISON_DATA_REQUEST:
      return {
        ...state,
        requesting: !action.payload?.layers,
      };

    case ExamActionTypes.UPDATE_COMPARISON_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        compareComparison: action.payload,
      };

    case ExamActionTypes.UPDATE_COMPARISON_DATA_ERROR:
      return {
        ...state,
        requesting: false,
      };

    case ExamActionTypes.UPDATE_BOTH_DATA_REQUEST:
      return {
        ...state,
        requesting: !action.payload?.layers,
      };

    case ExamActionTypes.UPDATE_BOTH_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        compareBoth:
          action.payload == null ? state.compareBoth : action.payload,
      };

    case ExamActionTypes.UPDATE_BOTH_DATA_ERROR:
      return {
        ...state,
        requesting: false,
      };

    case ExamActionTypes.GET_BOTH_EYES_OPPOSITE_EYE_SUCCESS:
      return {
        ...state,
        compareBoth: [
          ...state.compareBoth,
          {
            ...action.payload,
            activeSlide:
              action.payload.scans[
                Number((action.payload.scans?.length / 2).toFixed(0)) - 1
              ],
          },
        ],
      };

    case ExamActionTypes.UPDATE_PROGRESSION_DATA_REQUEST:
      return {
        ...state,
        requesting: true,
      };

    case ExamActionTypes.UPDATE_PROGRESSION_SCANS_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        compareProgress: {
          ...state.compareProgress,
          examsData: action.payload,
        },
      };

    case ExamActionTypes.UPDATE_PROGRESSION_DATA_ERROR:
      return {
        ...state,
        requesting: false,
      };

    case ExamActionTypes.GET_EXAM_THICKNESS_MAP_REQUEST:
      return {
        ...state,
      };

    case ExamActionTypes.GET_EXAM_THICKNESS_MAP_SUCCESS:
      if (action?.successType === 'gcc') {
        return {
          ...state,
          data: {
            ...state.data,
            heatmap: {
              ...state.data.heatmap,
              gcc: action.payload,
            },
          },
        };
      }

      return {
        ...state,
        requesting: false,
        data: {
          ...state.data,
          heatmap: {
            ...state.data.heatmap,
            main: action.payload,
          },
        },
      };

    case ExamActionTypes.GET_EXAM_THICKNESS_MAP_ERROR:
      return {
        ...state,
        requesting: false,
      };

    case ExamActionTypes.UPDATE_EXAM_TAB_REQUEST:
      return {
        ...state,
        requesting: true,
      };

    case ExamActionTypes.UPDATE_EXAM_TAB_SUCCESS:
      switch (action.payload.updateTo) {
        case 'BOTH': {
          return {
            ...state,
            requesting: false,
            compareBoth: action.payload.data,
          };
        }

        case 'COMPARISON': {
          return {
            ...state,
            requesting: false,
            compareComparison: action.payload.data,
          };
        }

        case 'PROGRESSION': {
          return {
            ...state,
            requesting: false,
            compareProgress: {
              ...state.compareProgress,
              examsData: action.payload.data,
            },
          };
        }

        default:
          return {
            ...state,
            requesting: false,
          };
      }

    case ExamActionTypes.SET_EXAM_HEATMAP_VISIBILITY:
      return {
        ...state,
        showHeatmap: action.payload,
      };

    case ExamActionTypes.GET_EXAM_MEASUREMENTS_LAYER_REQUEST:
      return {
        ...state,
        requesting: true,
      };

    case ExamActionTypes.GET_EXAM_MEASUREMENTS_LAYER_SUCCESS:
      return {
        ...state,
        requesting: false,
        measurements: {
          ...state.measurements,
          data: action.payload ?? state.measurements.data,
        },
      };

    case ExamActionTypes.GET_EXAM_MEASUREMENTS_LAYER_ERROR:
      return {
        ...state,
        requesting: false,
        data: {
          ...state.data,
          measurements_status: 'failed',
        },
      };

    case ExamActionTypes.UPDATE_EXAM_MEASUREMENTS_LAYER_REQUEST:
      return {
        ...state,
        requesting: true,
      };

    case ExamActionTypes.UPDATE_EXAM_MEASUREMENTS_LAYER_SUCCESS:
      return {
        ...state,
        requesting: false,
        measurements: {
          ...state.measurements,
          data: {
            ...state.measurements.data,
            pathologies: action.payload,
          },
        },
      };

    case ExamActionTypes.UPDATE_EXAM_MEASUREMENTS_LAYER_ERROR:
      return {
        ...state,
        requesting: false,
        data: {
          ...state.data,
          measurements_status: 'failed',
        },
      };

    case ExamActionTypes.DELETE_EXAM_MEASUREMENTS_LAYER_REQUEST:
      return {
        ...state,
        requesting: true,
      };

    case ExamActionTypes.DELETE_EXAM_MEASUREMENTS_LAYER_SUCCESS:
      return {
        ...state,
        requesting: false,
        measurements: {
          ...state.measurements,
          data: action.payload,
        },
      };

    case ExamActionTypes.DELETE_EXAM_MEASUREMENTS_LAYER_ERROR:
      return {
        ...state,
        requesting: false,
        data: {
          ...state.data,
          measurements_status: 'failed',
        },
      };

    case ExamActionTypes.GET_EXAM_MEASUREMENTS_MANUAL_REQUEST:
      return {
        ...state,
        requesting: true,
      };

    case ExamActionTypes.GET_EXAM_MEASUREMENTS_MANUAL_SUCCESS:
      return {
        ...state,
        requesting: false,
        measurements: {
          ...state.measurements,
          manual: action.payload ?? state.measurements.manual,
        },
      };

    case ExamActionTypes.GET_EXAM_MEASUREMENTS_MANUAL_ERROR:
      return {
        ...state,
        requesting: false,
        data: {
          ...state.data,
          measurements_status: 'failed',
        },
        measurements: {
          ...state.measurements,
          manual: [],
        },
      };

    case ExamActionTypes.GET_EXAM_MEASUREMENTS_MANUAL_ID_REQUEST:
      return {
        ...state,
        requesting: true,
      };

    case ExamActionTypes.GET_EXAM_MEASUREMENTS_MANUAL_ID_SUCCESS:
      return {
        ...state,
        requesting: false,
      };

    case ExamActionTypes.GET_EXAM_MEASUREMENTS_MANUAL_ID_ERROR:
      return {
        ...state,
        requesting: false,
        data: {
          ...state.data,
          measurements_status: 'failed',
        },
      };

    case ExamActionTypes.UPDATE_EXAM_MEASUREMENTS_MANUAL_REQUEST:
      return {
        ...state,
        requesting: true,
      };

    case ExamActionTypes.UPDATE_EXAM_MEASUREMENTS_MANUAL_SUCCESS:
      return {
        ...state,
        requesting: false,
        measurements: {
          ...state.measurements,
          manual: action.payload,
        },
      };

    case ExamActionTypes.UPDATE_EXAM_MEASUREMENTS_MANUAL_ERROR:
      return {
        ...state,
        requesting: false,
        data: {
          ...state.data,
          measurements_status: 'failed',
        },
        measurements: {
          ...state.measurements,
          manual: [],
        },
      };

    case ExamActionTypes.GET_EXAM_MEASUREMENTS_DISTANCE_REQUEST:
      return {
        ...state,
        requesting: true,
      };

    case ExamActionTypes.GET_EXAM_MEASUREMENTS_DISTANCE_SUCCESS:
      return {
        ...state,
        requesting: false,
      };

    case ExamActionTypes.GET_EXAM_MEASUREMENTS_DISTANCE_ERROR:
      return {
        ...state,
        requesting: false,
        data: {
          ...state.data,
          measurements_status: 'failed',
        },
      };

    case ExamActionTypes.SET_EXPANDED_GROUPS:
      switch (action.payload.group) {
        case 'area':
          return {
            ...state,
            measurements: {
              ...state.measurements,
              isAreaGroupsExpanded: action.payload.value,
            },
          };

        case 'volume':
          return {
            ...state,
            measurements: {
              ...state.measurements,
              isVolumeGroupsExpanded: action.payload.value,
            },
          };

        default:
          return state;
      }

    case ExamActionTypes.TOGGLE_EXPANDED_GROUPS:
      switch (action.payload.group) {
        case 'area':
          return {
            ...state,
            measurements: {
              ...state.measurements,
              isAreaGroupsExpanded: !state.measurements.isAreaGroupsExpanded,
            },
          };

        case 'volume':
          return {
            ...state,
            measurements: {
              ...state.measurements,
              isVolumeGroupsExpanded:
                !state.measurements.isVolumeGroupsExpanded,
            },
          };

        default:
          return state;
      }

    case ExamActionTypes.SET_EXAM_TABLE_MEASUREMENT_VALUE:
      return {
        ...state,
        measurements: {
          ...state.measurements,
          volumeMeasurementValue:
            state.measurements.volumeMeasurementValue === 'nl' ? 'mm' : 'nl',
        },
      };

    case ExamActionTypes.GET_GLAUCOMA_DATA_REQUEST:
      return {
        ...state,
        glaucoma: {
          ...state.glaucoma,
          isRequesting: true,
        },
      };

    case ExamActionTypes.GET_GLAUCOMA_DATA_SUCCESS:
      if (action.payload == null) {
        return {
          ...state,
          glaucoma: {
            isRequesting: false,
            isUnavailable: true,
          },
        };
      } else {
        return {
          ...state,
          glaucoma: {
            isRequesting: false,
            data: action.payload,
            isError: false,
          },
        };
      }

    case ExamActionTypes.GET_GLAUCOMA_DATA_ERROR:
      return {
        ...state,
        glaucoma: {
          ...state.glaucoma,
          isRequesting: false,
        },
      };

    case ExamActionTypes.GET_PROGRESSION_COMMON_PATHOLOGIES_DATA_REQUEST:
      return {
        ...state,
        compareProgress: {
          ...state.compareProgress,
          isRequestingCommonPathologiesData: true,
        },
      };

    case ExamActionTypes.GET_PROGRESSION_COMMON_PATHOLOGIES_DATA_SUCCESS:
      return {
        ...state,
        compareProgress: {
          ...state.compareProgress,
          isRequestingCommonPathologiesData: false,
          commonPathologiesData: action.payload,
        },
      };

    case ExamActionTypes.GET_PROGRESSION_COMMON_PATHOLOGIES_DATA_ERROR:
      return {
        ...state,
        compareProgress: {
          ...state.compareProgress,
          isRequestingCommonPathologiesData: false,
          commonPathologiesData: [],
        },
      };

    case ExamActionTypes.GET_PROGRESSION_HEATMAP_DATA_REQUEST:
      return {
        ...state,
        compareProgress: {
          ...state.compareProgress,
          isRequestingCommonHeatmapsData: true,
          commonHeatmapsData: {},
        },
      };

    case ExamActionTypes.GET_PROGRESSION_HEATMAP_DATA_SUCCESS:
      return {
        ...state,
        compareProgress: {
          ...state.compareProgress,
          isRequestingCommonHeatmapsData: false,
          commonHeatmapsData: action.payload ?? {},
        },
      };

    case ExamActionTypes.GET_PROGRESSION_HEATMAP_DATA_ERROR:
      return {
        ...state,
        compareProgress: {
          ...state.compareProgress,
          isRequestingCommonHeatmapsData: false,
          commonHeatmapsData: {},
        },
      };

    case ExamActionTypes.GET_PROGRESSION_PATHOLOGY_SCANS_DATA_REQUEST:
      return {
        ...state,
        compareProgress: {
          ...state.compareProgress,
          isRequestingPathologyScansData: true,
        },
      };

    case ExamActionTypes.GET_PROGRESSION_PATHOLOGY_SCANS_DATA_SUCCESS:
      return {
        ...state,
        compareProgress: {
          ...state.compareProgress,
          isRequestingPathologyScansData: false,
          pathologyScansData: action.payload ?? {},
        },
      };

    case ExamActionTypes.GET_PROGRESSION_PATHOLOGY_SCANS_DATA_ERROR:
      return {
        ...state,
        compareProgress: {
          ...state.compareProgress,
          isRequestingPathologyScansData: false,
          pathologyScansData: {},
        },
      };

    case ExamActionTypes.GET_PROGRESSION_STATE_REQUEST:
      return {
        ...state,
        compareProgress: {
          ...state.compareProgress,
          isRequestingProgressionState: true,
        },
      };

    case ExamActionTypes.GET_PROGRESSION_STATE_SUCCESS:
      return {
        ...state,
        compareProgress: {
          ...state.compareProgress,
          isRequestingProgressionState: false,
          examsData: action.payload,
        },
      };

    case ExamActionTypes.GET_PROGRESSION_STATE_ERROR:
      return {
        ...state,
        compareProgress: {
          ...state.compareProgress,
          isRequestingProgressionState: false,
        },
      };

    case ExamActionTypes.UPDATE_PROGRESSION_STATE_REQUEST:
      return {
        ...state,
        compareProgress: {
          ...state.compareProgress,
          isRequestingProgressionState: true,
        },
      };

    case ExamActionTypes.UPDATE_PROGRESSION_STATE_FINALLY:
      return {
        ...state,
        compareProgress: {
          ...state.compareProgress,
          isRequestingProgressionState: false,
        },
      };

    default:
      return state;
  }
};

export default examinationReducer;
