import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getCodeUpdateUserPassword } from 'api/userProfile';
import {
  getCodeUpdatePasswordError,
  getCodeUpdatePasswordSuccess,
} from 'redux/actions/userProfile';
import { userProfileConstants } from 'redux/constants/userProfile';
import { IGetCodeForPasswordData } from 'types/profile';
import notify from 'utils/toast';

function* getCodeUpdateUserPasswordWatcher() {
  yield takeEvery(
    userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_REQUEST,
    getCodeUpdateUserPasswordWorker
  );
}

function* getCodeUpdateUserPasswordWorker({
  payload,
}: IGetCodeForPasswordData) {
  try {
    const { data: updatePassword } = yield getCodeUpdateUserPassword(payload);
    yield put(getCodeUpdatePasswordSuccess());
    if (updatePassword.status === 'Error') {
      throw new Error(updatePassword.message);
    }
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    yield put(getCodeUpdatePasswordError(error));
    yield notify('error', errorMessage);
  }
}

export default function* userSaga() {
  yield fork(getCodeUpdateUserPasswordWatcher);
}
