import * as yup from 'yup';

import { CREATE_EXAMINATION_FLOW } from 'utils/constants';

export const validateSignIn = (t) => {
  return yup.object().shape({
    email: yup
      .string()
      .required(t('validation.required'))
      .email(t('validation.data_invalid')),
    password: yup.string().required(t('validation.required')),
  });
};

export const validateForgotPassword = (t) => {
  return yup.object().shape({
    email: yup
      .string()
      .required(t('validation.required'))
      .email(t('validation.data_invalid')),
  });
};

export const validateDemoRegisterStepOne = (t) =>
  yup
    .string()
    .required(t('validation.required'))
    .email(t('validation.email_invalid'));

export const validateDemoRegisterStepThree = (t) =>
  yup.object().shape({
    fullName: yup
      .string()
      .required(t('validation.required'))
      .matches(
        /^[ЁёҐґІіЇїЪъЄєa-zA-ZА-Яа-я0-9\s-]+$/,
        t('validation.letters_numbers_spaces_hyphens')
      ),
    country_id: yup.number().required(t('validation.required')),
    producer: yup.string().required(t('validation.required')),
    profession_id: yup.number().required(t('validation.required')),
    isDecisionMaker: yup.string(),
    promo_code: yup.string(),
  });

export const validatePasswordConfirmation = (t) => {
  return yup.object().shape({
    password: yup
      .string()
      .required(t('validation.required'))
      .min(8, t('validation.min_chars', { number: 8 }))
      .matches(/^(?=.*[a-z])/, t('validation.password_latin_lowercase'))
      .matches(/^(?=.*[A-Z])/, t('validation.password_latin_uppercase'))
      .matches(/^(?=.*[0-9])/, t('validation.password_number')),
    passwordConfirmation: yup
      .string()
      .required(t('validation.required'))
      .oneOf([yup.ref('password'), null], t('validation.password_match')),
  });
};

export const validateRegisterStepTwo = (t) => {
  return yup.object().shape({
    profession_id: yup.number().required(t('validation.required')),
    country_id: yup.number().required(t('validation.required')),
    privacy: yup.boolean().oneOf([true], t('validation.required')),
  });
};

export const validateUpdateUser = (t) => {
  return yup.object().shape({
    avatar: yup.mixed(),
    name: yup
      .string()
      .required(t('validation.required'))
      .matches(
        /^[ЁёҐґІіЇїЪъЄєa-zA-ZА-Яа-я0-9\s-]+$/,
        t('validation.letters_numbers_spaces_hyphens')
      )
      .min(2, t('validation.min_chars', { number: 2 }))
      .max(20, t('validation.max_chars', { number: 20 })),
    surname: yup
      .string()
      .required(t('validation.required'))
      .matches(
        /^[ЁёҐґІіЇїЪъЄєa-zA-ZА-Яа-я0-9\s-]+$/,
        t('validation.letters_numbers_spaces_hyphens')
      )
      .min(2, t('validation.min_chars', { number: 2 }))
      .max(20, t('validation.max_chars', { number: 20 })),
    email: yup.string().email(t('validation.data_invalid')),
    profession_id: yup.number().required(t('validation.required')),
    country_id: yup.number().required(t('validation.required')),
  });
};

export const validateGetCodeUpdatePassword = (t) => {
  return yup.object().shape({
    oldPassword: yup.string().required(t('validation.required')),
    newPassword: yup
      .string()
      .required(t('validation.required'))
      .min(8, t('validation.min_chars', { number: 8 }))
      .matches(/^(?=.*[a-z])/, t('validation.password_latin_lowercase'))
      .matches(/^(?=.*[A-Z])/, t('validation.password_latin_uppercase'))
      .matches(/^(?=.*[0-9])/, t('validation.password_number')),
    newPasswordConfirmation: yup
      .string()
      .required(t('validation.required'))
      .oneOf([yup.ref('newPassword'), null], t('validation.password_match')),
  });
};

export const validateUpdatePassword = (t) => {
  return yup.object().shape({
    confirmationCode: yup
      .string()
      .required(t('validation.required'))
      .min(2, t('validation.min_chars', { number: 2 })),
  });
};

export const validateReportSettingsUser = (t) => {
  return yup.object().shape({
    logo: yup.mixed(),
    company_name: yup
      .string()
      .required(t('validation.required'))
      .min(2, t('validation.min_chars', { number: 2 })),
    company_email: yup
      .string()
      .required(t('validation.required'))
      .email(t('validation.data_invalid')),
  });
};

export const validateImage = (imageFile) => {
  return imageFile.size / 1024 / 1024 < 3; //3mb size validation
};

export const validateUpdateExamination = (t) => {
  return yup.object().shape({
    eye: yup.string().required(t('validation.required')),
    width: yup.string().required(t('validation.required')),
    examination_day: yup.string().required(t('validation.required')),
    examination_month: yup.string().required(t('validation.required')),
    examination_year: yup.string().required(t('validation.required')),
    comment: yup
      .string()
      .notRequired()
      .max(250, t('validation.max_chars', { number: 250 })),
  });
};

export const validateCreateExamination = (t) => {
  return yup.lazy((values) => {
    if (typeof values['scan'] === 'object') {
      return yup.object().shape({
        scan: yup.object().required(t('validation.required')),
      });
    }

    switch (values['flow']) {
      case CREATE_EXAMINATION_FLOW.DICOM_IN_PATIENT:
      case CREATE_EXAMINATION_FLOW.ZIPPED_DICOM_IN_PATIENT:
        if (values['patient_from_dicom']) {
          return yup.object().shape({
            patient_from_dicom: yup
              .boolean()
              .oneOf([true], t('validation.required')),
            comment: yup
              .string()
              .notRequired()
              .max(250, t('validation.max_chars', { number: 250 })),
            file: yup.mixed().required(t('validation.required')),
            scan: yup.object().notRequired(),
          });
        }
        return yup.object().shape({
          pid: yup.string().required(t('validation.required')),
          patient_id: yup.string().required(t('validation.required')),
          comment: yup
            .string()
            .notRequired()
            .max(250, t('validation.max_chars', { number: 250 })),
          file: yup.mixed().required(t('validation.required')),
          scan: yup.object().notRequired(),
        });
      case CREATE_EXAMINATION_FLOW.IMAGES_IN_PATIENT:
        return yup.object().shape({
          pid: yup.string().required(t('validation.required')),
          patient_id: yup.string().required(t('validation.required')),
          width: yup.string().required(t('validation.required')),
          eye: yup.string().required(t('validation.required')),
          examination_day: yup.string().required(t('validation.required')),
          examination_month: yup.string().required(t('validation.required')),
          examination_year: yup.string().required(t('validation.required')),
          comment: yup
            .string()
            .notRequired()
            .max(250, t('validation.max_chars', { number: 250 })),
          file: yup.mixed().required(t('validation.required')),
          scan: yup.object().notRequired(),
        });

      default:
        break;
    }
  });
};

export const validateCreatePatient = (t) => {
  return yup.lazy((values) => {
    if (values['patient_from_dicom']) {
      return yup.object().shape({
        patient_from_dicom: yup
          .boolean()
          .oneOf([true], t('validation.required')),
        comment: yup
          .string()
          .notRequired()
          .max(250, t('validation.max_chars', { number: 250 })),
        file: yup.mixed().required(t('validation.required')),
        scan: yup.object().notRequired(),
      });
    } else {
      return yup.object().shape({
        pid: yup
          .string()
          .required(t('validation.required'))
          .min(2, t('validation.min_chars', { number: 2 })),
        name: yup
          .string()
          .required(t('validation.required'))
          .matches(
            /^[ЁёҐґІіЇїЪъЄєa-zA-ZА-Яа-я0-9.\s-]+$/,
            t('validation.letters_numbers_spaces_hyphens')
          )
          .max(50, t('validation.max_chars', { number: 50 })),
        surname: yup
          .string()
          .required(t('validation.required'))
          .matches(
            /^[ЁёҐґІіЇїЪъЄєa-zA-ZА-Яа-я0-9.\s-]+$/,
            t('validation.letters_numbers_spaces_hyphens')
          )
          .max(150, t('validation.max_chars', { number: 150 })),
        birth_day: yup
          .string()
          .required(t('validation.required'))
          .test('is-valid-day', t('validation.data_invalid'), function (value) {
            const { birth_month, birth_year } = this.parent;
            if (!birth_month || !birth_year || !value) {
              return true;
            }
            const day = parseInt(value, 10);
            const month = parseInt(birth_month, 10) - 1;
            const year = parseInt(birth_year, 10);
            const date = new Date(year, month, day);
            return (
              date.getFullYear() === year &&
              date.getMonth() === month &&
              date.getDate() === day
            );
          }),
        birth_month: yup.string().required(t('validation.required')),
        birth_year: yup.string().required(t('validation.required')),
        gender: yup.string().required(t('validation.required')),
        description: yup
          .string()
          .notRequired()
          .max(250, t('validation.max_chars', { number: 250 })),
      });
    }
  });
};
export const validateExamData = (t) => {
  return yup.object().shape({
    patient_name: yup
      .string()
      .required(t('validation.required'))
      .matches(
        /^[ЁёҐґІіЇїЪъЄєa-zA-ZА-Яа-я0-9.\s-]+$/,
        t('validation.letters_numbers_spaces_hyphens')
      )
      .max(50, t('validation.max_chars', { number: 50 })),
    patient_surname: yup
      .string()
      .required(t('validation.required'))
      .matches(
        /^[ЁёҐґІіЇїЪъЄєa-zA-ZА-Яа-я0-9.\s-]+$/,
        t('validation.letters_numbers_spaces_hyphens')
      )
      .max(50, t('validation.max_chars', { number: 50 })),
    gender: yup.string().required(t('validation.required')),
    pid: yup
      .string()
      .required(t('validation.required'))
      .min(3, t('validation.min_chars', { number: 3 }))
      .max(50, t('validation.max_chars', { number: 50 })),
    birth_date: yup.number().required(t('validation.required')),
    examination_date: yup.number().required(t('validation.required')),
    notes: yup.string().max(250, t('validation.max_chars', { number: 250 })),
  });
};

export const validateContactUs = (t) => {
  return yup.object().shape({
    first_name: yup
      .string()
      .required(t('validation.required'))
      .min(2, t('validation.min_chars', { number: 2 }))
      .max(50, t('validation.max_chars', { number: 50 })),

    email: yup
      .string()
      .required(t('validation.required'))
      .email(t('validation.data_invalid'))
      .max(50, t('validation.max_chars', { number: 50 })),

    message: yup
      .string()
      .required(t('validation.required'))
      .max(500, t('validation.max_chars', { number: 500 })),
  });
};

export const validateInviteTeamMember = (t) => {
  return yup.object().shape({
    name: yup
      .string()
      .required(t('validation.required'))
      .matches(
        /^[ЁёҐґІіЇїЪъЄєa-zA-ZА-Яа-я0-9\s-]+$/,
        t('validation.letters_numbers_spaces_hyphens')
      )
      .min(2, t('validation.min_chars', { number: 2 })),
    surname: yup
      .string()
      .required(t('validation.required'))
      .matches(
        /^[ЁёҐґІіЇїЪъЄєa-zA-ZА-Яа-я0-9\s-]+$/,
        t('validation.letters_numbers_spaces_hyphens')
      )
      .min(2, t('validation.min_chars', { number: 2 })),
    email: yup
      .string()
      .required(t('validation.required'))
      .email(t('validation.data_invalid')),
  });
};

export const validateSearchExamination = (isDoctorsFilterEnabled, t) => {
  return yup.object().shape({
    date: yup.string().required(t('validation.required')),
    examination_date_from: yup
      .number()
      .nullable()
      .when('date', {
        is: 'range',
        then: (schema) => schema.required(t('validation.required')),
        otherwise: (schema) => schema.notRequired(),
      }),
    examination_date_to: yup
      .number()
      .nullable()
      .when('date', {
        is: 'range',
        then: (schema) => schema.required(t('validation.required')),
        otherwise: (schema) => schema.notRequired(),
      }),
    medic_ids: isDoctorsFilterEnabled
      ? yup.string().required(t('validation.required'))
      : yup.string(),
  });
};

export const validateB2BBuyExamination = (t) => {
  return yup.object().shape({
    count: yup.string().required(t('validation.required')),
  });
};

export const validateMeasLabel = (t) => {
  return yup.object().shape({
    pathology: yup
      .string()
      .min(3, t('validation.min_chars', { number: 3 }))
      .max(60, t('validation.max_chars', { number: 60 }))
      .required(t('validation.required')),
  });
};

export const validateStoryBook = (t) => {
  return yup.object().shape({
    email: yup.string().required(t('validation.required')),
    password: yup.string().required(t('validation.required')),
    name: yup.string().required(t('validation.required')),
    profession_id: yup.number().required(t('validation.required')),
  });
};

export const validateSurveyUnsubscribe = (t) => {
  return yup.object().shape({
    reason: yup.string().required(t('validation.required')),
    feedback: yup
      .string()
      .max(1024, t('validation.max_chars', { number: 1024 })),
  });
};

export const validateOcuPlan = (t) => {
  return yup.object().shape({
    full_name: yup.string().required(t('validation.required')),
    business_name: yup
      .string()
      .required(t('validation.required'))
      .min(2, t('validation.min_chars', { number: 2 })),
    email: yup
      .string()
      .required(t('validation.required'))
      .email(t('validation.data_invalid')),
    privacy: yup.boolean().oneOf([true], t('validation.required')),
    confirm: yup.boolean().oneOf([true], t('validation.required')),
  });
};
