import { memo } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as AttentionIco } from 'assets/icons/attention-big.svg';
import MetaInfo from 'components/MetaInfo';
import Text from 'components/Text';
import { SimpleLayout } from 'layouts';

import styles from './OfflinePage.module.scss';

const OfflinePage = memo(() => {
  const { t } = useTranslation(undefined, { keyPrefix: 'offline' });

  return (
    <SimpleLayout>
      <div className={styles.wrapper}>
        <MetaInfo title='Offline page' />

        <div className={styles['icon-wrapper']}>
          <AttentionIco />
        </div>
        <Text tag='h1' color='dark-gray' className={styles.title}>
          {t('title')}
        </Text>
        <Text tag='h2' color='dark-gray' className={styles.subtitle}>
          {t('subtitle')}
        </Text>
      </div>
    </SimpleLayout>
  );
});

OfflinePage.displayName = 'OfflinePage';

export default OfflinePage;
