import React from 'react';

import cn from 'classnames';

import IconButton, { IconButtonProps } from '../IconButton';

import { ReactComponent as CloseIcon } from './icons/close.svg';

import s from './CloseButton.module.scss';

export interface TCloseButtonProps extends IconButtonProps {
  className?: string;
}

/**
 *  CloseButton
 *  'ui-modal-close-button' - need for make position in content or header
 *
 *  @param className
 * @param props
 */

export default function CloseButton({
  className = '',
  ...props
}: TCloseButtonProps) {
  return (
    <IconButton {...props} className={cn(s.CloseButton, className)}>
      <CloseIcon />
    </IconButton>
  );
}
