import React from 'react';

import cn from 'classnames';

import s from './ModalContent.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}

/**
 *  ModalContent
 *  Created by westprophet on 03.07.2024
 *  @param className
 * @param children
 */

export default function ModalContent({ className = '', children }: Props) {
  return (
    <article className={cn(s.ModalContent, className)}>{children}</article>
  );
}
