import * as React from 'react';

import cn from 'classnames';
import { Form, Formik } from 'formik';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';
import ErrorFallback from 'components/ErrorFallback';
import FormField from 'components/FormComponents';
import MetaInfo from 'components/MetaInfo';
import SpinnerSVG from 'components/SpinnerSVG';
import Text from 'components/Text';
import { sendContactUsRequest } from 'redux/actions/contactUs';
import { contactUsSelector } from 'redux/selectors/contactUs';
import { userProfileSelector } from 'redux/selectors/userProfile';
import { ACCEPT_FORMAT_FOR_UPLOAD } from 'utils/constants';
import { validateContactUs } from 'validation';

import styles from './ContactUs.module.scss';

const ContactUs = React.memo(() => {
  const dispatch = useDispatch();
  const { requesting } = useSelector(contactUsSelector);

  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: 'contact_us_page',
  });

  const userData = useSelector(userProfileSelector);

  const initialValues = {
    first_name: userData.data.name,
    email: userData.data.email,
    message: t('message.message_text'),
    file: null,
  };

  return (
    <div className={styles.page}>
      <MetaInfo title='Contact Us' />

      <SpinnerSVG
        className={styles.spinner}
        active={requesting}
        fillColor={'var(--theme-color)'}
      />
      <>
        <Text tag='h2' className={styles.title} color='dark'>
          {t('contact_us')}
        </Text>
        <Text tag='h3' className={styles.subtitle} color='dark-gray'>
          {t('fill_the_form')}
        </Text>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => window.location.reload()}
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={() => validateContactUs(i18n.t)}
            onSubmit={(values, actions) => {
              dispatch(
                sendContactUsRequest(values, {
                  resetForm: () => {
                    actions.setFieldValue('message', '', false);
                  },
                })
              );
            }}
          >
            {({ isValid, values, setFieldValue }) => {
              return (
                <Form className={styles.wrapper}>
                  <div className={styles.column}>
                    <FormField
                      type={'text'}
                      component={'text'}
                      name={'first_name'}
                      id={'first_name'}
                      placeholder={`${t('example_short')}.: Alex`}
                      label={t('first_name')}
                      className={cn(styles.input)}
                      labelType={'classic'}
                    />

                    <FormField
                      type={'text'}
                      component={'text'}
                      name={'email'}
                      id={'email'}
                      value={values.email}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value = event.target.value;

                        if (value.length > 50) return;

                        setFieldValue('email', value);
                      }}
                      placeholder={`${t('example_short')}.: alex@altris.io`}
                      label={t('email')}
                      className={cn(styles.input)}
                      labelType={'classic'}
                    />

                    <FormField
                      rows={5}
                      component={'textarea'}
                      name={'message'}
                      onChange={(
                        event: React.ChangeEvent<HTMLTextAreaElement>
                      ) => {
                        setFieldValue('message', event.target.value);
                      }}
                      placeholder={t('message.place_holder')}
                      label={t('message.place_holder')}
                      className={cn(styles.input, styles.textarea)}
                      labelType={'classic'}
                    />

                    <Button
                      type={'submit'}
                      className={cn(styles.button)}
                      disabled={!isValid}
                    >
                      {t('submit_form')}
                    </Button>
                  </div>
                  <div className={styles.column}>
                    <Text className={styles.text} color='dark-gray'>
                      {t('attach_file')}
                    </Text>
                    <Text className={styles.subtext} color='dark-gray'>
                      {t('attach_screenshot')}
                    </Text>
                    <FormField
                      component={'file-upload'}
                      name={'file'}
                      accept={ACCEPT_FORMAT_FOR_UPLOAD.IMAGE}
                      labelType={'classic'}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </ErrorBoundary>
      </>
    </div>
  );
});

ContactUs.displayName = 'ContactUs';

export default ContactUs;
