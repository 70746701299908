import { t } from 'i18next';
import { fork, put, takeLatest } from 'redux-saga/effects';

import { getExamination } from 'api/examination';
import {
  getExaminationError,
  getExaminationSuccess,
} from 'redux/actions/examination';
import { ExamActionTypes } from 'redux/constants/examination';
import { IWorkerProps } from 'redux/types/examination';
import { TExamination } from 'types/examination';
import notify from 'utils/toast';

function* getExaminationWatcher() {
  yield takeLatest(
    ExamActionTypes.GET_EXAMINATION_REQUEST,
    getExaminationWorker
  );
}

interface IExamination extends IWorkerProps {
  type: ExamActionTypes;
  payload: {
    exam_id: string;
  };
  action?: {
    default?: () => void;
    success?: (exam?: TExamination) => void;
    error?: () => void;
  };
  abortSignal?: AbortSignal;
}

function* getExaminationWorker({ payload, action, abortSignal }: IExamination) {
  try {
    const { data: examination } = yield getExamination(payload, abortSignal);
    // const result = examination.data;

    // Rewrite data for testing
    // const result: TExamination = {
    //   ...examination.data,
    // };
    if (examination.status === 'Error') {
      throw new Error(examination.message);
    }
    yield put(getExaminationSuccess(examination.data));

    action?.success?.(examination.data);
  } catch (error: any) {
    if (error === 'canceled') {
      return;
    }
    action?.error?.();
    console.error(error);
    const errorMessage =
      error.message || t('notifications.something_went_wrong');

    yield put(getExaminationError(errorMessage));

    yield notify('error', errorMessage);
  }
}

export default function* getExaminationSaga() {
  yield fork(getExaminationWatcher);
}
