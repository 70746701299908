import React from 'react';

import cn from 'classnames';

import { TCloseButtonProps } from '../../../CloseButton';
import useModalUI from '../../hooks/useModalUI';

import CloseButton from 'ui/CloseButton';

import s from './CloseButton.module.scss';

interface Props extends TCloseButtonProps {
  position?: 'fixed' | 'manual';
}

/**
 *  CloseButton
 *  'ui-modal-close-button' - need for make position in content or header
 *
 *  @param className
 * @param position
 * @param onClick
 * @param props
 */

export default function CloseModalButton({
  className = '',
  position = 'fixed',
  onClick,
  ...props
}: Props) {
  const { onClose } = useModalUI();
  return (
    <CloseButton
      {...props}
      className={cn(
        s.CloseButton,
        { 'ui-modal-close-button': position === 'fixed' },
        className
      )}
      onClick={onClick ?? onClose}
    />
  );
}
