import { openBlurModal } from 'redux/actions/modal';
import { store } from 'redux/store';
import { IPatient } from 'types/patients';
import { TProfile } from 'types/profile';
import { ga } from 'utils/helpers';
import {
  addExaminationModal,
  addPatientModal,
  noObjectsLeftModal,
} from 'utils/openModals';

export const addExaminationHandler = (
  patientsData: number,
  userData: TProfile,
  onboardingStep: string | null,
  activePatient?: IPatient
) => {
  ga(`Add_exam_start_home`);

  if (userData.plan.slug === 'free' && patientsData === 0 && !onboardingStep) {
    store.dispatch(openBlurModal());
    return;
  }

  if (patientsData === 0) {
    noObjectsLeftModal(userData.plan, 'examinations', userData.role.model);
  } else if (activePatient) {
    addExaminationModal(activePatient);
  }
};

export const addPatientHandler = (
  patientsData: number,
  userData: TProfile,
  onboardingStep: string | null
) => {
  ga(`Add_patient_start_home`);

  if (userData.plan.slug === 'free' && patientsData === 0 && !onboardingStep) {
    store.dispatch(openBlurModal());
    return;
  }

  addPatientModal();
};
