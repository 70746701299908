import {
  lazy,
  memo,
  Suspense,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import cn from 'classnames';
import * as dayjs from 'dayjs';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { ShepherdTourContext } from 'react-shepherd';

import { FDAManagerProvider } from '../../managers/FDAManager';

import { getDayCount } from './helper';
import DemoBookBanner from './sections/DemoBookBanner';
import FDAWarnMessage from './sections/FDAWarnMessage';
import TrialModal from './sections/TrialModal';

import Button from 'components/Button';
import ErrorFallback from 'components/ErrorFallback';
import Header from 'components/Header';
import Spinner from 'components/Spinner';
import { useIsDemo } from 'hooks/useIsDemo';
import useIsUserTrialExpired from 'hooks/useIsUserTrialExpired';
import useLocalStorage from 'hooks/useLocalStorage';
import useOnboarding from 'hooks/useOnboarding';
import { useOnline } from 'hooks/useOnline';
import useUserTimeFormat from 'hooks/useUserTimeFormat';
import OfflinePage from 'pages/Offline';
import { getSettingsRequest } from 'redux/actions/settings';
import { getUserProfileRequest } from 'redux/actions/userProfile';
import { selectAppSettings } from 'redux/selectors/appSettings';
import { modalSelector } from 'redux/selectors/modal';
import {
  currentPlanSelector,
  profileRoleSelector,
} from 'redux/selectors/userProfile';
import {
  B2B_TYPES,
  ROUTES,
  SUBSCRIPTION_STATUS,
  TWO_HOURS,
  USER_ROLES,
} from 'utils/constants';
import {
  renewSubscribeRejectB2BModal,
  renewSubscribeRejectModal,
  renewSubscribeWarningB2BModal,
  renewSubscribeWarningModal,
} from 'utils/openModals';

import styles from './PrivateLayout.module.scss';

const FirstSubscription = lazy(() => import('components/FirstSubscription'));
const MaintenanceMode = lazy(() => import('pages/MaintenanceMode'));

const PrivateLayout = memo(({ className }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const online = useOnline();
  /**
   * naming useOnboarding can be confusing, this functionality was created
   * before we had a tour, and it was used to show the first subscription
   * for the user. If you have a problem with the naming - check the date of
   * the commit where this functionality was added.
   */
  const isShowOnboarding = useOnboarding();
  const tour = useContext(ShepherdTourContext);
  const userCurrentPlan = useSelector(currentPlanSelector);
  const userRole = useSelector(profileRoleSelector);
  const { maintenance_mode } = useSelector(selectAppSettings);
  const { open: isModalOpen } = useSelector(modalSelector);

  const timeFormat = useUserTimeFormat();
  // eslint-disable-next-line no-console
  // console.log('timeFormat', timeFormat);

  const { localStorageKey: onboardingStep } = useLocalStorage('onboarding');

  const isDemo = useIsDemo();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.layout.private_layout',
  });
  const [openTrialModal, setOpenTrialModal] = useState(false);

  useEffect(() => {
    dispatch(getUserProfileRequest());
    dispatch(getSettingsRequest());
  }, []);

  /** Delete skip button when onBoardingTour was interrupted by reload the page */
  useLayoutEffect(() => {
    localStorage.removeItem('onboarding');
  }, []);

  const isTrialExpired = useIsUserTrialExpired();

  useEffect(() => {
    setOpenTrialModal(isTrialExpired);
  }, [isTrialExpired]);

  useEffect(() => {
    if (
      /**
       * Show renew popup only when out status past_due (get from backend)
       * with recurring mode month or year
       */
      userCurrentPlan?.subscription_status === SUBSCRIPTION_STATUS.PAST_DUE &&
      userRole.b2b_accrual_type_slug !== B2B_TYPES.INSTANT
    ) {
      const informRenew = (model) => {
        model === USER_ROLES.B2B
          ? renewSubscribeWarningB2BModal()
          : /** Add @param is_legacy to show right modal for user with legacy plan */
            renewSubscribeWarningModal(userCurrentPlan.is_legacy);

        sessionStorage.setItem('renew_timer', Date.now() + TWO_HOURS);
      };

      /**
       * Need to check what model we have. For b2b role we should to check
       * field @b2b_past_due_from for show these modal. For b2c we should to check
       * @current_period_start
       */
      if (userRole.model === USER_ROLES.B2B) {
        if (
          Math.abs(
            getDayCount(
              Date.parse(userCurrentPlan?.b2b_past_due_from) - Date.now()
            )
          ) < 6
        ) {
          /**
           * We have two popups for different conditions,
           * First popup is 'warning' popup, will be show
           * first 5 days and every 2 hours while we use
           * platform, this popup can be closed
           */
          let renewTimer = sessionStorage.getItem('renew_timer');

          // Check timer
          if (renewTimer) {
            if (Date.now() >= new Date(Number(renewTimer))) {
              informRenew(userRole.model);
            }
          } else {
            informRenew(userRole.model);
          }

          /**
           * Second popup is '' popup, will be show
           * after 5 days, and you can't use the platform,
           * this popup can't be closed
           */
        } else {
          // Remove old timer
          sessionStorage.removeItem('renew_timer');

          if (
            pathname.indexOf(ROUTES.PROFILE.MAIN) < 0 &&
            pathname.indexOf(ROUTES.ONBOARDING.MAIN) !== 0
          )
            renewSubscribeRejectB2BModal(userCurrentPlan);
        }
      } else {
        if (
          Math.abs(
            getDayCount(
              Date.parse(userCurrentPlan?.current_period_start) - Date.now()
            )
          ) < 6
        ) {
          let renewTimer = sessionStorage.getItem('renew_timer');

          // Check timer
          if (renewTimer) {
            if (Date.now() >= new Date(Number(renewTimer))) {
              informRenew(userRole.model);
            }
          } else {
            informRenew(userRole.model);
          }
        } else {
          // Remove old timer
          sessionStorage.removeItem('renew_timer');

          if (
            pathname.indexOf(ROUTES.PROFILE.MAIN) < 0 &&
            pathname.indexOf(ROUTES.ONBOARDING.MAIN) !== 0
          )
            renewSubscribeRejectModal(userCurrentPlan);
        }
      }
    }
  }, [userCurrentPlan, pathname, isModalOpen]);

  /**
   * Show this screen if we open app in mobile
   * We can register and subscribe, but can't use platform
   */
  // if (vw <= MOBILE_RESOLUTION && !isDemo) {
  //   return (
  //     <Suspense
  //       fallback={
  //         <div className={styles.inner}>
  //           <Spinner className={styles.spinner} />
  //         </div>
  //       }
  //     >
  //       <MobileAvailability />
  //     </Suspense>
  //   );
  // }

  /**
   * Show this screen if app is offline
   * Something happened with user's internet
   */
  if (!online) {
    return <OfflinePage />;
  }

  /**
   * Show this screen if our app is updating
   * Enable/disable this option from backend
   */
  if (maintenance_mode) {
    return (
      <Suspense
        fallback={
          <div className={styles.inner}>
            <Spinner className={styles.spinner} />
          </div>
        }
      >
        <MaintenanceMode />
      </Suspense>
    );
  }

  return (
    <FDAManagerProvider>
      <div className={cn(styles.wrapper, className)}>
        <Header className={styles.header} />
        {pathname === '/' && <FDAWarnMessage />}
        <main
          className={cn(styles.main, {
            [styles.height]: pathname.indexOf('examination/') < 0,
          })}
        >
          <div className={styles.inner}>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => window.location.reload()}
            >
              <Outlet />
            </ErrorBoundary>
          </div>
          {/* This 'modal' has been shown after the register. */}
          {isShowOnboarding && (
            <div className={styles.modal}>
              <Suspense fallback={<Spinner />}>
                <FirstSubscription switcher />
              </Suspense>
            </div>
          )}
        </main>
      </div>

      {/* Modal window with a message about the end of the trailing period */}

      <TrialModal
        open={openTrialModal}
        onClose={() => {
          setOpenTrialModal(false);
        }}
        date={dayjs
          .unix(userCurrentPlan.trial_ends_at)
          .format(timeFormat.toUpperCase())}
      />

      {/**
       * Show skip button only on onboarding tour. By click on 'Skip' button
       * we will open 'Skip modal" (delete @onboarding from sessionStorage )
       * and skip tour and show modal with download DICOM file
       */}
      {onboardingStep && (
        <Button
          onClick={() => tour.show('general-step-1')}
          className={styles['skip-button']}
        >
          {t('skip')}
        </Button>
      )}

      {isDemo && <DemoBookBanner />}
    </FDAManagerProvider>
  );
});

PrivateLayout.displayName = 'PrivateLayout';

export default PrivateLayout;
