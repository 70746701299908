import { useCallback, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { TAPIGetProfile } from '../../_api/AltrisRESTClient/routes/user/getProfile';
import useProfileQry, { getProfileKey } from '../../queries/user/getProfileQry';
import useFDAMut from '../../queries/user/useFDAMut';

import { TFDAManagerState } from './types';

export default function useInitialState(): TFDAManagerState {
  const [openAgreeModal, setOpenAgreeModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const queryClient = useQueryClient();
  const profileQry = useProfileQry();
  const acceptFDAMut = useFDAMut();
  // const isSessionChoice: boolean = sessionStorage.getItem('fda') === 'true';

  const isFDAUser = profileQry.data?.data.fda ?? false;
  const accepted = profileQry.data?.data.fda_accepted ?? null;

  const setFDA = useCallback(async (value: boolean) => {
    if (accepted) return;

    return acceptFDAMut.mutateAsync(
      { accepted: value },
      {
        onSuccess: () => {
          queryClient.setQueryData<TAPIGetProfile['TSuccess']>(
            getProfileKey(),
            (oldData) => {
              if (oldData) {
                return {
                  ...oldData,
                  data: { ...oldData.data, fda_accepted: value },
                };
              }
            }
          );
        },
      }
    );
  }, []);

  return {
    accepted,
    setFDA,
    isFDAUser,
    profileQry,
    agreementModal: {
      open: openAgreeModal,
      setOpen: setOpenAgreeModal,
    },
    confirmAgreeModal: {
      open: openConfirmModal,
      setOpen: setOpenConfirmModal,
    },
  };
}
