import {
  useMutation,
  MutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';

import { api } from '../../_api';
import {
  TSuccess,
  TPayload,
  TError,
} from '../../_api/AltrisRESTClient/routes/user/setFDA';

export type TFDAMutOpt = Omit<
  MutationOptions<TSuccess, TError, TPayload>,
  'mutationKey'
>;

export type TFDAMut = UseMutationResult<TSuccess, TError, TPayload, unknown>;

export default function useFDAMut(options?: TFDAMutOpt) {
  const opts = getFDAMutOpts(options);
  return useMutation<TSuccess, TError, TPayload>(opts);
}

export const getFDAMutKey = () => ['user', 'setFDA'];

export function getFDAMutOpts(options?: TFDAMutOpt) {
  return {
    ...options,
    mutationKey: getFDAMutKey(),
    mutationFn: (variables: TPayload) => api.user.setFDA(variables),
  };
}
