import React from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from '../../../../ui/Button';
import { Window, Wrapper, Content, Header, Footer } from '../../../../ui/Modal';
import { useFDAManager } from '../../FDAManagerProvider';

import s from './AgreementModal.module.scss';

/**
 *  AgreementModal
 */

export default function AgreementModal() {
  const { agreementModal, confirmAgreeModal, setFDA } = useFDAManager();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'fda_content.disclaimer_modal',
  });

  return (
    <Wrapper
      open={agreementModal.open}
      className={cn(s.AgreementModal)}
      shouldCloseOnOverlayClick
    >
      <Window className={s.window}>
        <Header>
          <h2>{t('title')}</h2>
        </Header>
        <Content>
          <ul>
            <li>{t('check_descriptions.description_1')}</li>
            <li>{t('check_descriptions.description_2')}</li>
            <li>{t('check_descriptions.description_3')}</li>
          </ul>
          <span className={s.label}>{t('confirm')}</span>
        </Content>
        <Footer>
          <Button
            color='primary'
            className={s.agree}
            size='lg'
            onClick={() => {
              setFDA(true);
              agreementModal.setOpen(false);
            }}
          >
            {t('buttons.agree')}
          </Button>
          <Button
            className={s.disagree}
            color='transparent'
            size='lg'
            onClick={() => {
              agreementModal.setOpen(false);
              confirmAgreeModal.setOpen(true);
            }}
          >
            {t('buttons.disagree')}
          </Button>
        </Footer>
      </Window>
    </Wrapper>
  );
}
