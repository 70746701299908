import { TCoupon } from './TCoupon';
import { TPlan } from './TPlan';
import { TProfile } from './TProfile';
import { TReportSetting } from './TReportSetting';
import { TRole } from './TRole';
import { TUpcomingPlan } from './TUpcomingPlan';

import { userProfileConstants } from 'redux/constants/userProfile';

export type { TRole, TCoupon, TProfile, TPlan, TUpcomingPlan, TReportSetting };

export enum SeverityLevelType {
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
}

export enum sourceType {
  DICOM = 'dicom',
  IMAGES = 'images',
}

export interface IUpdateUserProfile {
  avatar?: Blob | string;
  name: string;
  surname: string;
  profession_id: number;
  country_id: number;
  email: string;
}

export interface IGetCodeForPassword {
  old_password: string;
  new_password: string;
}

export interface IChangePassword {
  old_password: string;
  new_password: string;
  code: string;
}

export interface IUserPassword {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
  confirmationCode: string;
}

export interface TProfilesState {
  requesting: boolean;
  errors: unknown | null | string;
  data: TProfile;
  codeRequesting: boolean;
}

interface IGetProfileUserRequest {
  type: typeof userProfileConstants.GET_PROFILE_REQUEST;
}
interface IGetProfileUserSuccess {
  type: typeof userProfileConstants.GET_PROFILE_SUCCESS;
  payload: TProfile;
}
interface IGetProfileUserError {
  type: typeof userProfileConstants.GET_PROFILE_ERROR;
  payload: string;
}

type getUserProfileActionType =
  | IGetProfileUserRequest
  | IGetProfileUserSuccess
  | IGetProfileUserError;

export interface IUpdateUserData {
  type: userProfileConstants.UPDATE_PROFILE_REQUEST;
  payload: IUpdateUserProfile;
}
interface IUpdateProfileUserRequest {
  type: typeof userProfileConstants.UPDATE_PROFILE_REQUEST;
}
interface IUpdateProfileUserSuccess {
  type: typeof userProfileConstants.UPDATE_PROFILE_SUCCESS;
  payload: TProfile;
}
interface IUpdateProfileUserError {
  type: typeof userProfileConstants.UPDATE_PROFILE_ERROR;
  payload: string;
}

type updateUserProfileActionType =
  | IUpdateProfileUserRequest
  | IUpdateProfileUserSuccess
  | IUpdateProfileUserError;

export interface IGetCodeForPasswordData {
  type: userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_REQUEST;
  payload: IGetCodeForPassword;
}

interface IGetCodeForPasswordRequest {
  type: typeof userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_REQUEST;
}
interface IGetCodeForPasswordSuccess {
  type: typeof userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_SUCCESS;
}
interface IGetCodeForPasswordError {
  type: typeof userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_ERROR;
  payload: string;
}

interface IGetCodeForPasswordReset {
  type: typeof userProfileConstants.UPDATE_USER_GET_CODE_FOR_PASSWORD_RESET;
}

type getCodeForPasswordActionType =
  | IGetCodeForPasswordRequest
  | IGetCodeForPasswordSuccess
  | IGetCodeForPasswordError
  | IGetCodeForPasswordReset;

export interface IUpdatePasswordData {
  type: userProfileConstants.UPDATE_USER_PASSWORD_REQUEST;
  payload: IChangePassword;
  action: any;
}

interface IUpdatePasswordRequest {
  type: typeof userProfileConstants.UPDATE_USER_PASSWORD_REQUEST;
}
interface IUpdatePasswordSuccess {
  type: typeof userProfileConstants.UPDATE_USER_PASSWORD_SUCCESS;
}
interface IUpdatePasswordError {
  type: typeof userProfileConstants.UPDATE_USER_PASSWORD_ERROR;
  payload: string;
}

type updateUserPasswordActionType =
  | IUpdatePasswordRequest
  | IUpdatePasswordSuccess
  | IUpdatePasswordError;

export interface IUpdateReportSettingData {
  type: userProfileConstants.UPDATE_REPORT_SETTINGS_REQUEST;
  payload: {
    logo: Blob | string;
    company_email: string;
    company_name: string;
  };
}

interface IUpdateReportSettingRequest {
  type: typeof userProfileConstants.UPDATE_REPORT_SETTINGS_REQUEST;
}
interface IUpdateReportSettingSuccess {
  type: typeof userProfileConstants.UPDATE_REPORT_SETTINGS_SUCCESS;
  payload: TProfile;
}
interface IUpdateReportSettingError {
  type: typeof userProfileConstants.UPDATE_REPORT_SETTINGS_ERROR;
  payload: string;
}

type updateReportSettingActionType =
  | IUpdateReportSettingRequest
  | IUpdateReportSettingSuccess
  | IUpdateReportSettingError;

export type userProfileActions =
  | getUserProfileActionType
  | updateUserProfileActionType
  | getCodeForPasswordActionType
  | updateUserPasswordActionType
  | updateReportSettingActionType;
