import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { updateUserPassword } from 'api/userProfile';
import {
  getCodeUpdatePasswordReset,
  updatePasswordError,
  updatePasswordSuccess,
} from 'redux/actions/userProfile';
import { userProfileConstants } from 'redux/constants/userProfile';
import { IUpdatePasswordData } from 'types/profile';
import notify from 'utils/toast';

function* updateUserPasswordWatcher() {
  yield takeEvery(
    userProfileConstants.UPDATE_USER_PASSWORD_REQUEST,
    updateUserPasswordWorker
  );
}

function* updateUserPasswordWorker({ payload, action }: IUpdatePasswordData) {
  try {
    const { data: updatePassword } = yield updateUserPassword(payload);
    if (updatePassword.status === 'Error') {
      throw new Error(updatePassword.message);
    }
    yield put(updatePasswordSuccess());
    yield action.resetForm();
    yield notify('success', t('notifications.password_changed'));
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    /**
     * Checking with a text message is not a good idea
     * Maybe need change the backend part
     */
    if (error === 'Code is invalid.') {
      if (action.attempt > 1) {
        yield action.setAttempt(action.attempt - 1);
        yield put(updatePasswordError(error));
        return;
      }
      yield action.setAttempt(3);
      yield put(updatePasswordError(error));
      yield put(getCodeUpdatePasswordReset());
      yield action.resetForm();
      yield notify('error', errorMessage);
      return;
    }

    /**
     * * Catch other errors from the server
     */
    yield put(updatePasswordError(error));
    yield notify('error', t('notifications.something_went_wrong'));
  }
}

export default function* userSaga() {
  yield fork(updateUserPasswordWatcher);
}
