import { useEffect } from 'react';

import cn from 'classnames';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import EmptyListImg from 'assets/images/empty-list.svg';
import Button from 'components/Button';
import ErrorFallback from 'components/ErrorFallback';
import {
  ExaminationList,
  SkeletonExaminationList,
} from 'components/ExaminationList';
import MetaInfo from 'components/MetaInfo';
import Paper from 'components/Paper';
import { PatientList, SkeletonPatientList } from 'components/PatientList';
import PictureWithText from 'components/PictureWithText';
import SpinnerSVG from 'components/SpinnerSVG';
import Banner from 'components/TeamMemberList/Banner';
import Text from 'components/Text';
import {
  DashboardContextProvider,
  useDashboardContext,
} from 'contexts/Dashboard';
import { useIsDemo } from 'hooks/useIsDemo';
import { closeBlurModal, openBlurModal } from 'redux/actions/modal';
import { getPatientRequest } from 'redux/actions/patient';
import { patientsSelector } from 'redux/selectors/patients';
import {
  currentPlanSelector,
  userProfileSelector,
} from 'redux/selectors/userProfile';
import { ga } from 'utils/helpers';
import { addPatientModal, downloadDCMModal } from 'utils/openModals';

import styles from './Dashboard.module.scss';

const DashboardPage = () => {
  const dispatch = useDispatch();
  const pathname = useLocation();
  const userCurrentPlan = useSelector(currentPlanSelector);

  const isDemo = useIsDemo();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'dashboard',
  });

  const {
    data: patientsData,
    requesting: patientsRequesting,
    errors: patientsErrors,
  } = useSelector(patientsSelector);
  const { data: userData } = useSelector(userProfileSelector);
  const { selectedExam, filteredPatients } = useDashboardContext();
  const [searchParam] = useSearchParams();

  const getPatients = () => {
    dispatch(getPatientRequest());
  };

  /**
   * @param firstExamination need for open modal after onboarding tour
   * (also this modal should be open after you skip onboarding tour, client request)
   *
   */
  const firstExamination = searchParam.get('firstExamination');

  useEffect(() => {
    if (
      firstExamination &&
      !patientsData.patients.some(
        (patient) => patient.examinations?.length !== 0
      )
    ) {
      downloadDCMModal();
    }
  }, [firstExamination, patientsData.patients]);

  useEffect(() => {
    if (userCurrentPlan.slug === 'free' && userData.examinations_left === 0) {
      dispatch(openBlurModal());
    }

    return () => {
      dispatch(closeBlurModal());
    };
  }, [pathname]);

  useEffect(() => {
    getPatients();
    ga(`Open_page_Patients_list`);
  }, []);

  if (patientsRequesting) {
    return (
      <div className={styles.page}>
        <Paper className={styles.paper}>
          <SpinnerSVG
            className={styles.spinner}
            active={patientsRequesting}
            fillColor={'var(--theme-color)'}
          />
          <div className={styles.patients}>
            <SkeletonPatientList />
          </div>
          <div className={styles.examinations}>
            <SkeletonExaminationList />
          </div>
        </Paper>
      </div>
    );
  }

  /**
   * If we have error from backend on this page we
   * show the button with the request getPatient()
   */
  if (patientsErrors) {
    return (
      <div className={styles.page}>
        <Paper>
          <Button onClick={getPatients}>{t('error.try_again')}</Button>
        </Paper>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={getPatients}>
        <MetaInfo title='Dashboard' />

        <div className={styles.banners}>
          {userData.examinations_left === 0 && !isDemo && (
            <Banner
              className={styles.banner}
              appearance={'danger'}
              text={
                <Text>
                  {t('banner.title1')}{' '}
                  <a href={'mailto:mbraddon@altris.ai'} className={styles.link}>
                    {t('banner.link')}
                  </a>{' '}
                  {t('banner.title2')}
                </Text>
              }
            />
          )}
        </div>

        <div className={styles.page}>
          <Paper className={styles.paper}>
            {filteredPatients.length === 0 ? (
              <div className={styles.empty}>
                <PictureWithText
                  src={EmptyListImg}
                  alt={'Empty list'}
                  className={styles.picture}
                >
                  <Text color='gray' align='center' className={styles.title}>
                    {t('empty_data.title')}
                  </Text>
                  <Text color='gray' align='center' className={styles.subtitle}>
                    {t('empty_data.subtitle')}{' '}
                    <Button
                      appearance={'simple'}
                      onClick={addPatientModal}
                      className={styles['new-patient']}
                      disabled={isDemo}
                    >
                      “+{t('empty_data.add_new_patient')}”
                    </Button>
                  </Text>
                </PictureWithText>
              </div>
            ) : (
              <>
                <div className={cn(styles.patients, 'shepherd-patient-list')}>
                  <PatientList />
                </div>

                <div
                  className={cn(
                    styles.examinations,
                    'shepherd-examination-list'
                  )}
                >
                  <ExaminationList examinations={selectedExam} />
                </div>
              </>
            )}
          </Paper>
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default function Dashboard() {
  return (
    /**
     * ! Experimental implementation react.Context for this page
     */
    <DashboardContextProvider>
      <DashboardPage />
    </DashboardContextProvider>
  );
}
