import { useState, useEffect, useRef } from 'react';

// Дебаунс-функция
const debounce = (func: () => void, delay: number) => {
  let timeout: NodeJS.Timeout;
  return () => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };
};

// refactor return boolean to speed

const useIsSlowConnection = () => {
  const [previousProgress, setPreviousProgress] = useState<number | null>(null);
  const [lastUpdateTime, setLastUpdateTime] = useState<number | null>(null);
  const [isSlowConnection, setIsSlowConnection] = useState<boolean>(false); // Флаг для 3 раз подряд
  const slowConnectionCount = useRef<number>(0);
  const hasReachedSlowLimit = useRef<boolean>(false);

  const minimalSpeed = 10240; // 10 MB в килобайтах (10 * 1024 KB/sec)

  const debounceRef = useRef(
    debounce(() => {
      if (slowConnectionCount.current >= 5) {
        setIsSlowConnection(true);
        hasReachedSlowLimit.current = true;
      }
    }, 1000)
  );

  useEffect(() => {
    const calculateSpeed = () => {
      if (hasReachedSlowLimit.current) {
        return;
      }

      const currentProgressString = sessionStorage.getItem('uploadProgress');
      const currentTime = new Date().getTime();

      if (currentProgressString !== null) {
        const currentProgress = parseFloat(currentProgressString);
        if (previousProgress !== null && lastUpdateTime !== null) {
          const progressDelta = currentProgress - previousProgress;
          const timeDelta = (currentTime - lastUpdateTime) / 1000; // в секундах

          if (timeDelta > 0) {
            const speed = progressDelta / timeDelta; // байты/сек
            const speedInKBps = speed / 1024; // перевод в килобайты/сек

            if (speedInKBps != 0) {
              const isSlow = speedInKBps < minimalSpeed;

              // Если соединение медленное
              if (isSlow) {
                slowConnectionCount.current += 1;
                debounceRef.current();
              } else {
                slowConnectionCount.current = 0;
                setIsSlowConnection(false);
              }
            }
          }
        }
        setPreviousProgress(currentProgress);
        setLastUpdateTime(currentTime);
      }
    };

    const interval = setInterval(calculateSpeed, 1000);

    return () => clearInterval(interval);
  }, [previousProgress, lastUpdateTime]);

  return isSlowConnection;
};

export default useIsSlowConnection;
