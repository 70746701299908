import { memo } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ReactComponent as ThankYouIcon } from 'assets/icons/thankyou.svg';
import Button from 'components/Button';
import Text from 'components/Text';
import { getAuthenticatedTrue } from 'redux/actions/userAuthenticated';

import styles from './StepThankYou.module.scss';

const SteepThankYou = memo(() => {
  const loginMethod = localStorage.getItem('method');
  const dispatch = useDispatch();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'register_user_page.step_thankyou',
  });
  const authorizationHandler = () => {
    dispatch(getAuthenticatedTrue(loginMethod ?? ''));
  };

  return (
    <>
      <Text className={styles.title} color='light' tag='h1'>
        {t('title')}
      </Text>
      <Text className={styles.subtitle} color='light' align='center'>
        {t('subtitle')}
      </Text>
      <ThankYouIcon className={styles.thankyou} />
      <Button
        onClick={authorizationHandler}
        className={cn(styles.signin, styles.button)}
      >
        {t('button')}
      </Button>
    </>
  );
});

SteepThankYou.displayName = 'SteepThankYou';

export default SteepThankYou;
