import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useFDAManager } from '../../../../../managers/FDAManager';

import Button from 'ui/Button';

import s from './FooterSection.module.scss';

export default function FooterSection() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'examination.header.dropdown',
  });

  const { agreementModal, accepted, isFDAUser } = useFDAManager();
  if (!accepted && isFDAUser)
    return (
      <div className={cn(s.FooterSection)}>
        <Button onClick={() => agreementModal.setOpen(true)}>
          {t('use_altris_ai')}
        </Button>
      </div>
    );
}
