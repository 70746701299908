import { memo, useId, useMemo } from 'react';

import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { altrisRESTClient } from '../../_api';
import { queryClient } from '../../index';

import { smallStyles } from './smallStyles';

import { ReactComponent as EnglishIcon } from 'assets/icons/languages/english.svg';
import { ReactComponent as FrenchIcon } from 'assets/icons/languages/french.svg';
import { ReactComponent as GermanIcon } from 'assets/icons/languages/german.svg';
import { ReactComponent as ItalianIcon } from 'assets/icons/languages/italian.svg';
import { ReactComponent as PortugueseIcon } from 'assets/icons/languages/portuguese.svg';
import { ReactComponent as SpanishIcon } from 'assets/icons/languages/spanish.svg';
import FormField from 'components/FormComponents';
import { cleanCache } from 'utils/apiCache';
import { LOCAL_STORAGE_LANGUAGE_KEY } from 'utils/constants';

interface LanguageDropdownProps {
  className?: string;
  selectStyles?: any;
  label?: string;
  small?: boolean;
}

const LANGUAGE_SELECT_OPTIONS = [
  { value: 'en', label: 'English', icon: <EnglishIcon /> },
  { value: 'de', label: 'Deutsch', icon: <GermanIcon /> },
  { value: 'fr', label: 'Français', icon: <FrenchIcon /> },
  { value: 'es', label: 'Español', icon: <SpanishIcon /> },
  { value: 'pt', label: 'Português', icon: <PortugueseIcon /> },
  { value: 'it', label: 'Italiano', icon: <ItalianIcon /> },
] as const;

const LanguageDropdown = memo(
  ({ className, selectStyles, label, small }: LanguageDropdownProps) => {
    const { i18n } = useTranslation();

    const id = useId();

    const selectOptions = useMemo(
      () =>
        small
          ? LANGUAGE_SELECT_OPTIONS.map((item) => ({
              ...item,
              label: item.value.toUpperCase(),
            }))
          : LANGUAGE_SELECT_OPTIONS,
      [small]
    );

    return (
      <Formik
        initialValues={{
          [`language-${id}`]: i18n.language,
        }}
        onSubmit={() => {}}
        enableReinitialize
      >
        {() => (
          <Form>
            <FormField
              component={'select'}
              name={`language-${id}`}
              options={selectOptions}
              customStyles={small ? smallStyles : selectStyles}
              className={className}
              labelType={'classic'}
              label={label}
              small={small}
              onChange={async (item: any) => {
                const lang = item?.value ?? 'en';
                altrisRESTClient.language = lang;
                localStorage.setItem(
                  LOCAL_STORAGE_LANGUAGE_KEY,
                  item?.value ?? 'en'
                );

                await cleanCache();
                location.reload();
                await queryClient.resetQueries();
              }}
              withIcons
              withCheckMarkOnSelectedOption
            />
          </Form>
        )}
      </Formik>
    );
  }
);

LanguageDropdown.displayName = 'LanguageDropdown';

export default LanguageDropdown;
