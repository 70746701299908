import { useMemo, ComponentType } from 'react';

import { TFlashMessageType } from '../FlashMessage';
import { ReactComponent as AlertCircleIcon } from '../icons/alert-circle.svg';
import { ReactComponent as AlertTriangleIcon } from '../icons/alert-triangle.svg';
import { ReactComponent as CheckmarkCircleIcon } from '../icons/checkmark-circle.svg';
import { ReactComponent as InfoIcon } from '../icons/info-green.svg';

import useFlashMessage from './useFlashMessage';

interface SVGIconComponentProps extends React.SVGProps<SVGSVGElement> {}

type IconType = ComponentType<SVGIconComponentProps> | undefined;

export default function useDefaultIcon(
  icon?: TFlashMessageType | ComponentType<SVGIconComponentProps> | boolean
): IconType {
  const type = useFlashMessage();

  return useMemo(() => {
    if (!icon) return; // undefined or false
    let ic = icon;

    // if true
    if (typeof icon === 'boolean' && Boolean(icon)) ic = type;
    if (typeof ic === 'string') {
      switch (ic.toLowerCase()) {
        case 'red':
        case 'error':
          return AlertTriangleIcon;
        case 'yellow':
        case 'warning':
          return AlertCircleIcon;
        case 'green':
        case 'success':
          return CheckmarkCircleIcon;
        case 'info':
          return InfoIcon;
        default:
          return;
      }
    }

    //if component
    return icon as unknown as ComponentType<SVGIconComponentProps>;
  }, [type, icon]);
}
