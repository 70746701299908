import CloseButton from './components/CloseButton';
import Wrapper from './Modal';
import Window from './sections/Inner';
import Content from './sections/ModalContent';
import Footer from './sections/ModalFooter';
import Header from './sections/ModalHeader';
import { TModalProps } from './types';

export default {
  Wrapper,
  Window,
  Content,
  Header,
  Footer,
  CloseButton,
};

export { Wrapper, Window, Content, Header, Footer, CloseButton };

export type { TModalProps };
