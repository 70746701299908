import { OverlayLine } from 'pages/Examination/Examination.types';
import { ExamActionTypes } from 'redux/constants/examination';
import { IGetExamMeasSaving, IReduxAction } from 'redux/types/examination';
import {
  CompareProgressData,
  TExamination,
  ExamSegmentationScan,
  ExamSingleLayers,
  TExtendedExam,
  ICompareExam,
  ICreateExamination,
  IEtdrsData,
  IHeatMapProps,
  IMatrixNumber,
  IMeasDistanceRequest,
  IMeasurementsPolygonScan,
  IMeasurementsScan,
  IUpdateMeasManualData,
  PatientExamDataType,
  ScanLayersStatus,
  TGlaucomaExam,
  UpdateExaminationData,
} from 'types/examination';
import {
  IApiError,
  IApiGetExamScan,
  IMeasUpdateData,
} from 'types/global/patient';

export const resetExamState = () => ({
  type: ExamActionTypes.RESET_EXAMINATION_STATE,
});

export const getDataForOnBoardingTour = () => ({
  type: ExamActionTypes.GET_DATA_FOR_ONBOARDING_TOUR,
});

// CREATE EXAMINATION
export const createExaminationRequest = (
  data: ICreateExamination,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.CREATE_EXAMINATION_REQUEST,
  payload: data,
  action: action,
});

export const createExaminationSuccess = () => ({
  type: ExamActionTypes.CREATE_EXAMINATION_SUCCESS,
});

export const createExaminationError = (error: string) => ({
  type: ExamActionTypes.CREATE_EXAMINATION_ERROR,
  payload: error,
});

// GET EXAMINATION

interface IExamAction extends Omit<IReduxAction, 'success'> {
  success?: (loadedData: TExamination) => void;
}

export const getExaminationRequest = (
  data: { exam_id: string },
  action?: IExamAction,
  abortSignal?: AbortSignal
) => ({
  type: ExamActionTypes.GET_EXAMINATION_REQUEST,
  payload: data,
  action: action,
  abortSignal,
});

export const getExaminationSuccess = (data: TExamination) => ({
  type: ExamActionTypes.GET_EXAMINATION_SUCCESS,
  payload: data,
});

export const getExaminationError = (error: string) => ({
  type: ExamActionTypes.GET_EXAMINATION_ERROR,
  payload: error,
});

// UPDATE EXAMINATION
export const updateExaminationRequest = (
  data: UpdateExaminationData,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.UPDATE_EXAMINATION_REQUEST,
  payload: data,
  action: action,
});

export const updateExaminationSuccess = () => ({
  type: ExamActionTypes.UPDATE_EXAMINATION_SUCCESS,
});

export const updateExaminationError = (error: string) => ({
  type: ExamActionTypes.UPDATE_EXAMINATION_ERROR,
  payload: error,
});

// DELETE EXAMINATION
export const deleteExaminationRequest = (data: { exam_id: string }) => ({
  type: ExamActionTypes.DELETE_EXAMINATION_REQUEST,
  payload: data,
});

export const deleteExaminationSuccess = () => ({
  type: ExamActionTypes.DELETE_EXAMINATION_SUCCESS,
});

export const deleteExaminationError = (data: string) => ({
  type: ExamActionTypes.DELETE_EXAMINATION_ERROR,
  payload: data,
});

// GET EXAMINATION STATUS
export const getExaminationStatusRequest = (data: PatientExamDataType) => ({
  type: ExamActionTypes.GET_EXAMINATION_STATUS_REQUEST,
  payload: data,
});

export const getExaminationStatusError = (error: string) => ({
  type: ExamActionTypes.GET_EXAMINATION_STATUS_ERROR,
  payload: error,
});

// SET COMPARE EXAM
export interface ICompareExamAction extends Omit<IReduxAction, 'success'> {
  success?: (examination_ids: string[]) => void;
}

export const setCompareData = (
  data: ICompareExam,
  action?: ICompareExamAction
) => ({
  type: ExamActionTypes.COMPARE_EXAMINATION_REQUEST,
  payload: data,
  action,
});

export const setCompareDataSuccess = () => ({
  type: ExamActionTypes.COMPARE_EXAMINATION_SUCCESS,
});

export const setCompareBothSuccess = (
  data: TExamination[] | TExtendedExam[]
) => ({
  type: ExamActionTypes.COMPARE_EXAMINATION_BOTH_SUCCESS,
  payload: data,
});

export const setCompareCompSuccess = (
  data: TExamination[] | TExtendedExam[]
) => ({
  type: ExamActionTypes.COMPARE_EXAMINATION_COMPARISON_SUCCESS,
  payload: data,
});

export const setCompareProgressScansDataSuccess = (
  data: Partial<CompareProgressData>
) => ({
  type: ExamActionTypes.COMPARE_EXAMINATION_PROGRESS_SCANS_DATA_SUCCESS,
  payload: data,
});

export const setCompareDataError = (data: string) => ({
  type: ExamActionTypes.COMPARE_EXAMINATION_ERROR,
  payload: data,
});

// SET FILTER EXAM
export const filterCompareProgress = (data: TExtendedExam[]) => ({
  type: ExamActionTypes.FILTER_EXAMINATION_PROGRESS,
  payload: data,
});

// GET Segmentation
export const getExaminationSegmentationRequest = (
  data: {
    exam_id: string;
    scans_id: string[];
  },
  action?: IReduxAction
) => ({
  type: ExamActionTypes.SCAN_SEGMENTATION_REQUEST,
  payload: data,
  action: action,
});

export const getExaminationSegmentationSuccess = (
  data: ExamSegmentationScan
) => ({
  type: ExamActionTypes.SCAN_SEGMENTATION_SUCCESS,
  payload: data,
});
//
export const getExaminationSegmentationError = (error: string) => ({
  type: ExamActionTypes.SCAN_SEGMENTATION_ERROR,
  payload: error,
});

// GET report Segmentation
export const getSegmentationReportRequest = (
  data: {
    exam_id: string;
    scans_id: string[];
  },
  action?: IReduxAction,
  abortSignal?: AbortSignal
) => ({
  type: ExamActionTypes.SCAN_SEGMENTATION_REPORT_REQUEST,
  payload: data,
  action: action,
  abortSignal,
});

export const getSegmentationReportRequestSuccess = (
  data: ExamSegmentationScan
) => ({
  type: ExamActionTypes.SCAN_SEGMENTATION_REPORT_SUCCESS,
  payload: data,
});

export const getSegmentationReportRequestError = (error: string) => ({
  type: ExamActionTypes.SCAN_SEGMENTATION_REPORT_ERROR,
  payload: error,
});

interface IStatusAction extends Omit<IReduxAction, 'success'> {
  success?: ({ status }: { status: ScanLayersStatus }) => void;
}

// GET LAYERS STATUS
export const getLayersStatusRequest = (
  data: { exam_id: string },
  action?: IStatusAction,
  abortSignal?: AbortSignal
) => ({
  type: ExamActionTypes.GET_LAYERS_STATUS_REQUEST,
  payload: data,
  action: action,
  abortSignal,
});

export const getLayersStatusSuccess = (
  data: ScanLayersStatus,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_LAYERS_STATUS_SUCCESS,
  payload: data,
  action: action,
});

export const getLayersStatusError = (error: string) => ({
  type: ExamActionTypes.GET_LAYERS_STATUS_ERROR,
  payload: error,
});

// GET LAYERS
export const getLayersRequest = (
  data: { exam_id: string; scan_id: string },
  action?: IReduxAction
) => ({
  type: ExamActionTypes.SCAN_LAYERS_REQUEST,
  payload: data,
  action: action,
});

export const getLayersSuccess = (data: ExamSingleLayers) => ({
  type: ExamActionTypes.SCAN_LAYERS_SUCCESS,
  payload: data,
});

export const getLayersError = (error: string) => ({
  type: ExamActionTypes.SCAN_LAYERS_ERROR,
  payload: error,
});

// Favorite scans

export const setFavoriteScan = (
  data: { exam_id: string; scan_id: string },
  action?: IReduxAction
) => ({
  type: ExamActionTypes.SET_FAVORITE_SCAN_REQUEST,
  payload: data,
  action: action,
});

export const setFavoriteScanSuccess = (
  data: TExamination,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.SET_FAVORITE_SCAN_SUCCESS,
  payload: data,
  action: action,
});

export const setFavoriteScanError = (data: any, action?: IReduxAction) => ({
  type: ExamActionTypes.SET_FAVORITE_SCAN_ERROR,
  payload: data,
  action: action,
});

export const getMainETDRS = (
  data: { exam_id: string },
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_ETDRS_MAIN_REQUEST,
  payload: data,
  action: action,
});

export const getMainETDRSSuccess = (
  data: IEtdrsData,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_ETDRS_MAIN_SUCCESS,
  payload: data,
  action: action,
});

export const getLayerETDRS = (
  data: { exam_id: string; layers: string[] },
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_ETDRS_SINGLE_REQUEST,
  payload: data,
  action: action,
});

export const getLayersETDRS = (
  data: { exam_id: string; layers: string[] },
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_ETDRS_RANGE_REQUEST,
  payload: data,
  action: action,
});

export const getSingleETDRSSuccess = (
  data: {
    layers?: string[];
    data: IEtdrsData;
  },
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_ETDRS_SINGLE_SUCCESS,
  payload: data,
  action: action,
});

export const getRangeETDRSSuccess = (
  data: {
    layers?: string[];
    data: IEtdrsData;
  },
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_ETDRS_RANGE_SUCCESS,
  payload: data,
  action: action,
});

export const getGccETDRS = (
  data: { exam_id: string; layers: string[] },
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_ETDRS_GCC_REQUEST,
  payload: data,
  action: action,
});

export const getGccETDRSSuccess = (
  data: {
    layers?: string[];
    data: IEtdrsData;
  },
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_ETDRS_GCC_SUCCESS,
  payload: data,
  action: action,
});

export const getETDRSError = (data: string, action?: IReduxAction) => ({
  type: ExamActionTypes.GET_ETDRS_ERROR,
  payload: data,
  action: action,
});

export const updateComparisonDataRequest = (
  data: {
    exam_id: string;
    layers?: IHeatMapProps;
  },
  action?: IReduxAction
) => ({
  type: ExamActionTypes.UPDATE_COMPARISON_DATA_REQUEST,
  payload: data,
  action: action,
});

export const updateComparisonDataSuccess = (
  data: TExtendedExam[],
  action?: IReduxAction
) => ({
  type: ExamActionTypes.UPDATE_COMPARISON_DATA_SUCCESS,
  payload: data,
  action: action,
});

export const updateComparisonDataError = (
  data?: string,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.UPDATE_COMPARISON_DATA_ERROR,
  payload: data,
  action: action,
});

export const updateExamTabRequest = (
  data: {
    exam_id: string;
    updateTo: 'BOTH' | 'COMPARISON' | 'PROGRESSION';
    currentExam: boolean;
  },
  action?: IReduxAction
) => ({
  type: ExamActionTypes.UPDATE_EXAM_TAB_REQUEST,
  payload: data,
  action: action,
});

export const updateExamTabSuccess = (
  data: {
    updateTo: 'BOTH' | 'COMPARISON' | 'PROGRESSION';
    data: TExamination[] | TExtendedExam[];
  },
  action?: IReduxAction
) => ({
  type: ExamActionTypes.UPDATE_EXAM_TAB_SUCCESS,
  payload: data,
  action: action,
});

export interface IUpdateBothAction extends Omit<IReduxAction, 'success'> {
  success?: (data: TExtendedExam) => void;
}

export const updateBothDataRequest = (
  data: {
    exam_id: string;
    layers?: IHeatMapProps;
    main?: boolean;
    withoutSave?: boolean;
  },
  action?: IUpdateBothAction
) => ({
  type: ExamActionTypes.UPDATE_BOTH_DATA_REQUEST,
  payload: data,
  action: action,
});

export const updateBothDataSuccess = (
  data?: TExtendedExam[],
  action?: IReduxAction
) => ({
  type: ExamActionTypes.UPDATE_BOTH_DATA_SUCCESS,
  payload: data,
  action: action,
});

export const updateBothDataError = (data?: string, action?: IReduxAction) => ({
  type: ExamActionTypes.UPDATE_BOTH_DATA_ERROR,
  payload: data,
  action: action,
});

export const getBothEyesOppositeEyeRequest = (
  exam_id: string,
  action?: IReduxAction,
  abortSignal?: AbortSignal
) => ({
  type: ExamActionTypes.GET_BOTH_EYES_OPPOSITE_EYE_REQUEST,
  payload: exam_id,
  action: action,
  abortSignal,
});

export const getBothEyesOppositeEyeSuccess = (
  data: TExtendedExam,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_BOTH_EYES_OPPOSITE_EYE_SUCCESS,
  payload: data,
  action: action,
});

export const updateProgressionDataRequest = (
  data: {
    exam_id: string;
  },
  action?: IReduxAction
) => ({
  type: ExamActionTypes.UPDATE_PROGRESSION_DATA_REQUEST,
  payload: data,
  action: action,
});

export const updateProgressionScansDataSuccess = (
  data: TExtendedExam[],
  action?: IReduxAction
) => ({
  type: ExamActionTypes.UPDATE_PROGRESSION_SCANS_DATA_SUCCESS,
  payload: data,
  action: action,
});

export const updateProgressionDataError = (
  data?: string,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.UPDATE_PROGRESSION_DATA_ERROR,
  payload: data,
  action: action,
});

export const getExamThicknessMapRequest = (
  data: { exam_id: string; layers?: string[]; type?: 'gcc' },
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_EXAM_THICKNESS_MAP_REQUEST,
  payload: data,
  action: action,
});

export const getExamThicknessMapSuccess = (
  data: IMatrixNumber,
  type?: string,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_EXAM_THICKNESS_MAP_SUCCESS,
  payload: data,
  successType: type || false,
  action: action,
});

export const getExamThicknessMapError = (
  data?: string,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_EXAM_THICKNESS_MAP_ERROR,
  payload: data,
  action: action,
});

export const setExamHeatmapVisibility = (
  visibility: boolean,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.SET_EXAM_HEATMAP_VISIBILITY,
  payload: visibility,
  action: action,
});

interface IMeasLayersAction extends Omit<IReduxAction, 'success'> {
  success?: (cleanData: IMeasurementsScan) => void;
}
export const getExamMeasLayersRequest = (
  data: IGetExamMeasSaving,
  action?: IMeasLayersAction,
  abortSignal?: AbortSignal
) => ({
  type: ExamActionTypes.GET_EXAM_MEASUREMENTS_LAYER_REQUEST,
  payload: data,
  action: action,
  abortSignal,
});

export const getExamMeasLayersSuccess = (
  data?: IMeasurementsScan,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_EXAM_MEASUREMENTS_LAYER_SUCCESS,
  payload: data,
  action: action,
});

export const getExamMeasLayersError = (
  data: IApiError,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_EXAM_MEASUREMENTS_LAYER_ERROR,
  payload: data,
  action: action,
});

interface IMeasUpdateLayersAction extends Omit<IReduxAction, 'success'> {
  success?: (cleanData: IMeasurementsPolygonScan[]) => void;
}
export const updateExamMeasLayersRequest = (
  data: IMeasUpdateData,
  action?: IMeasUpdateLayersAction
) => ({
  type: ExamActionTypes.UPDATE_EXAM_MEASUREMENTS_LAYER_REQUEST,
  payload: data,
  action: action,
});

export const updateExamMeasLayersSuccess = (
  data: IMeasurementsPolygonScan[],
  action?: IReduxAction
) => ({
  type: ExamActionTypes.UPDATE_EXAM_MEASUREMENTS_LAYER_SUCCESS,
  payload: data,
  action: action,
});

export const updateExamMeasLayersError = (
  data: IApiError,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.UPDATE_EXAM_MEASUREMENTS_LAYER_ERROR,
  payload: data,
  action: action,
});

interface IMeasDeleteManualAction extends Omit<IReduxAction, 'success'> {
  success?: (newMeasData: IMeasurementsScan) => void;
}

export const deleteExamMeasLayersRequest = (
  data: IApiGetExamScan,
  action?: IMeasDeleteManualAction
) => ({
  type: ExamActionTypes.DELETE_EXAM_MEASUREMENTS_LAYER_REQUEST,
  payload: data,
  action: action,
});

export const deleteExamMeasLayersSuccess = (
  data: IMeasurementsScan,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.DELETE_EXAM_MEASUREMENTS_LAYER_SUCCESS,
  payload: data,
  action: action,
});

export const deleteExamMeasLayersError = (
  data: IApiError,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.DELETE_EXAM_MEASUREMENTS_LAYER_ERROR,
  payload: data,
  action: action,
});

interface IMeasManualAction extends Omit<IReduxAction, 'success'> {
  success?: (data: OverlayLine[]) => void;
}

export const getExamMeasManualRequest = (
  data: IGetExamMeasSaving,
  action?: IMeasManualAction,
  abortSignal?: AbortSignal
) => ({
  type: ExamActionTypes.GET_EXAM_MEASUREMENTS_MANUAL_REQUEST,
  payload: data,
  action: action,
  abortSignal,
});

export const getExamMeasManualSuccess = (
  data?: OverlayLine[],
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_EXAM_MEASUREMENTS_MANUAL_SUCCESS,
  payload: data,
  action: action,
});

export const getExamMeasManualError = (
  data: IApiError,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_EXAM_MEASUREMENTS_MANUAL_ERROR,
  payload: data,
  action: action,
});

export const updateExamMeasManualRequest = (
  data: IUpdateMeasManualData,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.UPDATE_EXAM_MEASUREMENTS_MANUAL_REQUEST,
  payload: data,
  action: action,
});

export const updateExamMeasManualSuccess = (
  data: OverlayLine[],
  action?: IReduxAction
) => ({
  type: ExamActionTypes.UPDATE_EXAM_MEASUREMENTS_MANUAL_SUCCESS,
  payload: data,
  action: action,
});

export const updateExamMeasManualError = (
  data: IApiError,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.UPDATE_EXAM_MEASUREMENTS_MANUAL_ERROR,
  payload: data,
  action: action,
});

interface IDistanceAction extends Omit<IReduxAction, 'success'> {
  success: (distance: number) => void;
}
export const getExamMeasDistanceRequest = (
  data: IMeasDistanceRequest,
  action?: IDistanceAction
) => ({
  type: ExamActionTypes.GET_EXAM_MEASUREMENTS_DISTANCE_REQUEST,
  payload: data,
  action: action,
});

export const getExamMeasDistanceSuccess = (
  data: { distance: number },
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_EXAM_MEASUREMENTS_DISTANCE_SUCCESS,
  payload: data,
  action: action,
});

export const getExamMeasDistanceError = (
  data: IApiError,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_EXAM_MEASUREMENTS_DISTANCE_ERROR,
  payload: data,
  action: action,
});

export interface IExamManualIdActions extends Omit<IExamAction, 'success'> {
  success: (manual_id: string[]) => void;
}
export const getExamMeasManualIdRequest = (
  data: {
    exam_id: string;
  },
  action?: IExamManualIdActions,
  abortSignal?: AbortSignal
) => ({
  type: ExamActionTypes.GET_EXAM_MEASUREMENTS_MANUAL_ID_REQUEST,
  payload: data,
  action: action,
  abortSignal,
});

export const getExamMeasManualIdSuccess = (
  data: string[],
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_EXAM_MEASUREMENTS_MANUAL_ID_SUCCESS,
  payload: data,
  action: action,
});

export const getExamMeasManualIdError = (
  data: IApiError,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_EXAM_MEASUREMENTS_MANUAL_ID_ERROR,
  payload: data,
  action: action,
});

// Get report layers + masks

export interface getLayersReportAction extends Omit<IReduxAction, 'success'> {
  success: (data: ExamSingleLayers) => void;
}

export const getLayersReportRequest = (
  data: { exam_id: string; scan_id: string },
  action?: getLayersReportAction
) => ({
  type: ExamActionTypes.SCAN_LAYERS_REPORT_REQUEST,
  payload: data,
  action: action,
});

export const getLayersReportSuccess = () => ({
  type: ExamActionTypes.SCAN_LAYERS_REPORT_SUCCESS,
  payload: {},
});

export const getLayersReportError = () => ({
  type: ExamActionTypes.SCAN_LAYERS_REPORT_ERROR,
  payload: {},
});

export const toggleExpandedGroups = (group: 'area' | 'volume') => ({
  type: ExamActionTypes.TOGGLE_EXPANDED_GROUPS,
  payload: { group },
});

export const setExpandedGroups = (
  group: 'area' | 'volume',
  value: boolean
) => ({
  type: ExamActionTypes.SET_EXPANDED_GROUPS,
  payload: { group, value },
});

export const toggleVolumesMeasurementValues = () => ({
  type: ExamActionTypes.SET_EXAM_TABLE_MEASUREMENT_VALUE,
});

export const getPathologiesCalculationZone = (
  payload: {
    exam_id: string;
    scan_id: string;
  },
  action?: {
    default?: () => void;
    success?: (cleanData?: IMeasurementsScan) => void;
    error?: () => void;
  },
  abortSignal?: AbortSignal
) => ({
  type: ExamActionTypes.GET_PATHOLOGIES_CALCULATION_ZONE,
  payload,
  action,
  abortSignal,
});

export interface IGlaucomaRequestAction extends Omit<IReduxAction, 'success'> {
  success: (data?: TGlaucomaExam | null) => void;
}

export const getGlaucomaDataRequest = (
  data: { exam_id: string },
  action?: IGlaucomaRequestAction,
  abortSignal?: AbortSignal
) => ({
  type: ExamActionTypes.GET_GLAUCOMA_DATA_REQUEST,
  payload: data,
  action,
  abortSignal,
});

export const getGlaucomaDataSuccess = (
  data?: TGlaucomaExam | null,
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_GLAUCOMA_DATA_SUCCESS,
  payload: data,
  action,
});

export const getGlaucomaDataError = (action?: IReduxAction) => ({
  type: ExamActionTypes.GET_GLAUCOMA_DATA_ERROR,
  action,
});

export interface getProgressionCommonPathologiesDataAction
  extends Omit<IReduxAction, 'success'> {
  success: (
    data: CompareProgressData['commonPathologiesData'],
    scan_ids: string[]
  ) => void;
}

export const getProgressionCommonPathologiesDataRequest = (
  data: { examination_ids: string[] },
  action?: getProgressionCommonPathologiesDataAction,
  abortSignal?: AbortSignal
) => ({
  type: ExamActionTypes.GET_PROGRESSION_COMMON_PATHOLOGIES_DATA_REQUEST,
  payload: data,
  action,
  abortSignal,
});

export const getProgressionCommonPathologiesDataSuccess = (
  data: CompareProgressData['commonHeatmapsData'],
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_PROGRESSION_COMMON_PATHOLOGIES_DATA_SUCCESS,
  payload: data,
  action,
});

export const getProgressionCommonPathologiesDataError = (
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_PROGRESSION_COMMON_PATHOLOGIES_DATA_ERROR,
  action,
});

export interface getProgressionHeatmapDataAction
  extends Omit<IReduxAction, 'success'> {
  success: (data: CompareProgressData['commonHeatmapsData']) => void;
}

export const getProgressionHeatmapDataRequest = (
  data: {
    examination_ids: string[];
    pathology_id: string;
    withoutSave?: boolean;
  },
  action?: getProgressionHeatmapDataAction,
  abortSignal?: AbortSignal
) => ({
  type: ExamActionTypes.GET_PROGRESSION_HEATMAP_DATA_REQUEST,
  payload: data,
  action,
  abortSignal,
});

export const getProgressionHeatmapDataSuccess = (
  data?: CompareProgressData['commonHeatmapsData'],
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_PROGRESSION_HEATMAP_DATA_SUCCESS,
  payload: data,
  action,
});

export const getProgressionHeatmapDataError = (action?: IReduxAction) => ({
  type: ExamActionTypes.GET_PROGRESSION_HEATMAP_DATA_ERROR,
  action,
});

export interface getProgressionPathologyScansDataAction
  extends Omit<IReduxAction, 'success'> {
  success: (data: CompareProgressData['pathologyScansData']) => void;
}

export const getProgressionPathologyScansDataRequest = (
  data: { scan_ids: string[]; pathology_id: string; withoutSave?: boolean },
  action?: getProgressionPathologyScansDataAction,
  abortSignal?: AbortSignal
) => ({
  type: ExamActionTypes.GET_PROGRESSION_PATHOLOGY_SCANS_DATA_REQUEST,
  payload: data,
  action,
  abortSignal,
});

export const getProgressionPathologyScansDataSuccess = (
  data?: CompareProgressData['pathologyScansData'],
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_PROGRESSION_PATHOLOGY_SCANS_DATA_SUCCESS,
  payload: data,
  action,
});

export const getProgressionPathologyScansDataError = (
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_PROGRESSION_PATHOLOGY_SCANS_DATA_ERROR,
  action,
});

export interface getProgressionStateAction
  extends Omit<IReduxAction, 'success'> {
  success: (scanIds: string[]) => void;
}

export const getProgressionStateRequest = (
  exam_id: string,
  action?: getProgressionStateAction,
  abortSignal?: AbortSignal
) => ({
  type: ExamActionTypes.GET_PROGRESSION_STATE_REQUEST,
  payload: exam_id,
  action,
  abortSignal,
});

export const getProgressionStateSuccess = (
  data: CompareProgressData['examsData'],
  action?: IReduxAction
) => ({
  type: ExamActionTypes.GET_PROGRESSION_STATE_SUCCESS,
  payload: data,
  action,
});

export const getProgressionStateError = (action?: IReduxAction) => ({
  type: ExamActionTypes.GET_PROGRESSION_STATE_ERROR,
  action,
});

export const updateProgressionStateRequest = (
  exam_ids: string[],
  action?: getProgressionPathologyScansDataAction
) => ({
  type: ExamActionTypes.UPDATE_PROGRESSION_STATE_REQUEST,
  payload: exam_ids,
  action,
});

export const updateProgressionStateFinally = (action?: IReduxAction) => ({
  type: ExamActionTypes.UPDATE_PROGRESSION_STATE_FINALLY,
  action,
});
