import { createContext } from 'react';

import { TFDAManagerState } from './types';

export const FDAManagerContext = createContext<TFDAManagerState>({
  accepted: false,
  setFDA: async function (): Promise<any> {
    return;
  },
  profileQry: null,
  isFDAUser: null, // null is not initial
  agreementModal: {
    open: false,
    setOpen: function () {},
  },
  confirmAgreeModal: {
    open: false,
    setOpen: function () {},
  },
});

export default FDAManagerContext;
