import { useCallback, useState } from 'react';

import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { demoSendEmail } from 'api/demo';
import { ReactComponent as Envelope } from 'assets/icons/icon-envelope.svg';
import { ReactComponent as Info } from 'assets/icons/info-green.svg';
import Button from 'components/Button';
import FormField from 'components/FormComponents';
import SpinnerSVG from 'components/SpinnerSVG';
import Text from 'components/Text';
import ErrorComponent from 'pages/DemoUserRegistration/components/ErrorComponent';
import { validateDemoRegisterStepOne } from 'validation/index';

import styles from './FirstStep.module.scss';

interface FirstStepProps {
  onNextStep: () => void;
  setEmail: (value: string) => void;
  email: string;
}

const FirstStep = ({ onNextStep, setEmail, email }: FirstStepProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayError, setDisplayError] = useState<string | undefined>();

  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: 'demo_user_registration.first_step',
  });

  const onFormSubmit = useCallback(
    async (email: string) => {
      setIsSubmitting(true);

      try {
        validateDemoRegisterStepOne(i18n.t).validateSync(email);

        await demoSendEmail(email);

        onNextStep();
        setEmail(email);
      } catch (error) {
        // @ts-ignore
        const cleanedError = String(error).split(':').pop().trim();
        setDisplayError(cleanedError);
      } finally {
        setIsSubmitting(false);
      }
    },
    [onNextStep, setEmail]
  );

  return (
    <>
      <Text className={styles.title} color='light' tag='h1' align='center'>
        {t('welcome')}
      </Text>

      <Formik
        initialValues={{
          email: email,
        }}
        onSubmit={({ email }) => {
          onFormSubmit(email);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className={styles.form} noValidate={true}>
            <SpinnerSVG
              className={styles.spinner}
              active={isSubmitting}
              fillColor={'var(--theme-color)'}
            />

            <div className={styles['form-field']}>
              <Text className={styles['field-label']} color='light' tag='span'>
                {t('email_label')}
              </Text>
              <FormField
                type={'email'}
                component={'text'}
                name={'email'}
                id={'email'}
                label={`${t('email')}*`}
                icon={<Envelope />}
                className={styles['email-field']}
                value={values.email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = event.target.value;

                  if (value.length > 50) return;

                  setFieldValue('email', value);
                }}
              />
              {displayError && <ErrorComponent>{displayError}</ErrorComponent>}

              <div className={styles['info-wrapper']}>
                <div>
                  <Info />
                </div>

                <Text className={styles['info-text']} color='light' tag='span'>
                  {t('info')}
                </Text>
              </div>
            </div>

            <Button
              type={'submit'}
              disabled={isSubmitting}
              className={styles.button}
            >
              {t('submit_form')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FirstStep;
